// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileInput_fileInput__HMB1k {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.FileInput_imgBox__khbc9 {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
}

.FileInput_preview__LAb0E {
  max-width: 250px;
  max-height: 250px;
}

.FileInput_closeButton__hA8RQ {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  background-color: rgb(220, 220, 220);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 5px;
}

.FileInput_closeButton__hA8RQ:hover {
  background-color: rgb(200, 200, 200);
}

.FileInput_imageInput__hfFlJ {
  display: none;
}

.FileInput_imageButton__F1tEN {
  width: 30px;
  height: 30px;
  background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/components/FileInput.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,wBAAmB;EAAnB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;EACV,QAAQ;AACV;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,6BAA6B;AAC/B","sourcesContent":[".fileInput {\n  display: flex;\n  flex-direction: column;\n  gap: 25px;\n}\n\n.imgBox {\n  width: fit-content;\n  height: fit-content;\n  position: relative;\n}\n\n.preview {\n  max-width: 250px;\n  max-height: 250px;\n}\n\n.closeButton {\n  width: 25px;\n  height: 25px;\n  border-radius: 15px;\n  background-color: rgb(220, 220, 220);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  right: 5px;\n  top: 5px;\n}\n\n.closeButton:hover {\n  background-color: rgb(200, 200, 200);\n}\n\n.imageInput {\n  display: none;\n}\n\n.imageButton {\n  width: 30px;\n  height: 30px;\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileInput": `FileInput_fileInput__HMB1k`,
	"imgBox": `FileInput_imgBox__khbc9`,
	"preview": `FileInput_preview__LAb0E`,
	"closeButton": `FileInput_closeButton__hA8RQ`,
	"imageInput": `FileInput_imageInput__hfFlJ`,
	"imageButton": `FileInput_imageButton__F1tEN`
};
export default ___CSS_LOADER_EXPORT___;
