import { createContext, useContext, useEffect, useState } from "react";
import { login, logout, refresh_token } from "../api/AuthApi";
import { getUser } from "../api/UserApi";
import { checkLikeOrDislike, createLikeOrDislike } from "../api/PostApi";

const initial_user = {
  id: 0,
  status: null,
  username: null,
  password: null,
  email: null,
  role: null,
  social_provider: null,
  social_id: null,
  created_at: null,
  updated_at: null,
  myPosts: null,
  posts: [],
  comments: [],
};

const initial_token = {
  access_token: null,
  refresh_token: null,
};

const AuthContext = createContext({
  user: initial_user,
  token: initial_token,
  isOnline: false,
  setLogIn: () => {},
  setLogOut: () => {},
  setUpdate: () => {},
});

function AuthProvider({ children }) {
  const [user, setUser] = useState(initial_user);
  const [token, setToken] = useState(initial_token);
  const [isOnline, setIsOnline] = useState(false);

  async function setLogIn(loginData) {
    const { code, msg, nextUser, nextToken } = await login(loginData);
    if (code < 300) {
      setUser((prevUser) => ({
        ...prevUser,
        ...nextUser,
      }));
      setToken((prevToken) => ({
        ...prevToken,
        ...nextToken,
      }));
      setIsOnline(true);
    }
    return { code, msg };
  }

  async function setLogOut(token) {
    const { code, msg } = await logout(token);
    if (code < 300) {
      setUser({
        ...user,
        ...initial_user,
      });
      setToken((prevToken) => ({ ...prevToken, ...initial_token }));
      setIsOnline(false);
      alert("로그아웃 되었습니다");
    } else {
      setUser({
        ...user,
        ...initial_user,
      });
      setToken((prevToken) => ({ ...prevToken, ...initial_token }));
      setIsOnline(false);
      alert(code, msg, "로그아웃");
    }
  }

  // async function setUpdate() {
  //   //미완
  //   //update 함수가 완성되어야 사용 가능
  //   //update 함수 = 프로필 편집
  //   const { code, msg, userData } = await update(updateData);
  //   if (200 <= code < 300) {
  //     setUser((prevData) => ({
  //       ...prevData,
  //       userData,
  //     }));
  //   } else {
  //     console.log(code, msg);
  //   }
  // }

  async function handleLikeAndDislike(targetData) {
    const { id, type, like_type, current_type } = targetData;
    console.log(current_type);
    switch (type) {
      case "posts":
        const posts = user.posts;
        const targetPostIdx = posts.findIndex((post) => post.id === id);
        if (targetPostIdx >= 0) {
          createLikeOrDislike({ like_type, post_id: id }, token.access_token);
          const targetPost = posts.find((post) => post.id === id);
          if (targetPost.origin === current_type) {
            setUser((prevUser) => ({
              ...prevUser,
              posts: [
                ...posts.splice(0, targetPostIdx),
                { ...targetPost, like_type: current_type, request_type: false },
                ...posts.splice(targetPostIdx + 1),
              ],
            }));
          } else {
            const request_type =
              like_type === "none" // none,dislike -> like == like // none,like -> dislike == dislike //
                ? targetPost.origin
                : like_type;
            setUser((prevUser) => ({
              ...prevUser,
              posts: [
                ...posts.splice(0, targetPostIdx),
                { ...targetPost, like_type: current_type, request_type },
                ...posts.splice(targetPostIdx + 1),
              ],
            }));
          }
          return;
        }
        const { code: code1, msg: msg1 } = await checkLikeOrDislike(
          {
            like_type,
            post_id: id,
          },
          token.access_token
        );
        if (code1 < 300) {
          switch (msg1) {
            case `${like_type}로 변경되었습니다.`: // =  원래는 like_type의 반대였음
              if (like_type === "like") {
                setUser((prevUser) => ({
                  ...prevUser,
                  posts: [
                    ...posts,
                    {
                      id,
                      origin: "dislike",
                      like_type: "like",
                    },
                  ],
                }));
                return "dislike";
              } else {
                setUser((prevUser) => ({
                  ...prevUser,
                  posts: [
                    ...posts,
                    {
                      id,
                      origin: "like",
                      like_type: "dislike",
                    },
                  ],
                }));
                return "like";
              }
            case `${like_type} 취소되었습니다.`: // =  원래는 like_type이었음
              createLikeOrDislike(
                { like_type, post_id: id },
                token.access_token
              );
              setUser((prevUser) => ({
                ...prevUser,
                posts: [
                  ...posts,
                  {
                    id,
                    origin: like_type,
                    like_type,
                  },
                ],
              }));
              return like_type;
            case "success": // =  원래는 none이었음
              setUser((prevUser) => ({
                ...prevUser,
                posts: [
                  ...posts,
                  {
                    id,
                    origin: "none",
                    like_type,
                  },
                ],
              }));
              return "none";
            default:
              return;
          }
        }
        break;
      case "comments":
        const comments = user.comments;
        const targetCommentIdx = comments.findIndex(
          (comment) => comment.id === id
        );
        if (targetCommentIdx >= 0) {
          createLikeOrDislike(
            { like_type, comment_id: id },
            token.access_token
          );
          const targetComment = comments.find((comment) => comment.id === id);
          if (targetComment.origin === like_type) {
            setUser((prevUser) => ({
              ...prevUser,
              comments: [
                ...comments.splice(0, targetCommentIdx),
                {
                  ...targetComment,
                  like_type: current_type,
                  request_type: false,
                },
                ...comments.splice(targetCommentIdx + 1),
              ],
            }));
          } else {
            const request_type =
              like_type === "none" // none,dislike -> like == like // none,like -> dislike == dislike //
                ? targetComment.origin
                : like_type;
            setUser((prevUser) => ({
              ...prevUser,
              comments: [
                ...comments.splice(0, targetCommentIdx),
                { ...targetComment, like_type: current_type, request_type },
                ...comments.splice(targetCommentIdx + 1),
              ],
            }));
          }
          return;
        }
        const { code: code2, msg: msg2 } = await checkLikeOrDislike(
          {
            like_type,
            comment_id: id,
          },
          token.access_token
        );
        if (code2 < 300) {
          switch (msg2) {
            case `${like_type}로 변경되었습니다.`: // =  원래는 like_type의 반대였음
              if (like_type === "like") {
                setUser((prevUser) => ({
                  ...prevUser,
                  comments: [
                    ...comments,
                    {
                      id,
                      origin: "dislike",
                      like_type: "like",
                    },
                  ],
                }));
                return "dislike";
              } else {
                setUser((prevUser) => ({
                  ...prevUser,
                  comments: [
                    ...comments,
                    {
                      id,
                      origin: "like",
                      like_type: "dislike",
                    },
                  ],
                }));
                return "like";
              }
            case `${like_type} 취소되었습니다.`: // =  원래는 like_type이었음
              createLikeOrDislike(
                { like_type, comment_id: id },
                token.access_token
              );
              setUser((prevUser) => ({
                ...prevUser,
                comments: [
                  ...comments,
                  {
                    id,
                    origin: like_type,
                    like_type,
                  },
                ],
              }));
              return like_type;
            case "success": // =  원래는 none이었음
              setUser((prevUser) => ({
                ...prevUser,
                comments: [
                  ...comments,
                  {
                    id,
                    origin: "none",
                    like_type,
                  },
                ],
              }));
              return "none";
            default:
              return;
          }
        }
        break;
      default:
        return;
    }
  }

  useEffect(() => {
    //첫 렌더링시 (새로고침시 포함) 로그인 인증된 사용자의 정보를 조회
    //access 쿠키 이용
    async function gettingUser() {
      const refreshToken = localStorage.getItem("refresh_token");
      if (refreshToken) {
        const { code, msg, access_token } = await refresh_token({
          refresh_token: refreshToken,
        });
        localStorage.removeItem("refresh_token");
        if (access_token) {
          const { nextUser } = await getUser({ user_id: 0 }, access_token);
          if (nextUser) {
            setToken((prevToken) => ({
              ...prevToken,
              refresh_token: refreshToken,
              access_token,
            }));
            setUser((prevUser) => ({
              ...prevUser,
              ...nextUser,
            }));
            setIsOnline(true);
          }
        } else {
          alert("다시 로그인해주세요");
        }
      }
    }
    ///
    gettingUser();
  }, []);

  window.addEventListener("unload", () => {
    // const url = `${process.env.REACT_APP_API_URL}api/post/like`;
    // user.posts.forEach((post) => {
    //   if (post.request_type) {
    //     navigator.sendBeacon(
    //       url,
    //       JSON.stringify({
    //         like_type: post.request_type,
    //         post_id: post.id,
    //       })
    //     );
    //   }
    // });
    // user.comments.forEach((comment) => {
    //   if (comment.request_type) {
    //     navigator.sendBeacon(
    //       url,
    //       JSON.stringify({
    //         like_type: comment.request_type,
    //         comment_id: comment.id,
    //       })
    //     );
    //   }
    // });
    if (token.refresh_token) {
      localStorage.setItem("refresh_token", token.refresh_token);
    }
  });

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        isOnline,
        setLogIn,
        setLogOut,
        // setUpdate,
        handleLikeAndDislike,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("반드시 AuthProvider 안에서 사용해야 합니다.");
  }

  return context;
}

export { AuthProvider, useAuth };
