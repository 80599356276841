import Warn from "./Warn";
import styles from "./ReadingOptions.module.css";
import classNames from "classnames";

function ReadingOptions({
  manageMode = false,
  isMe,
  role,
  activeTab,
  handleClick,
}) {
  const userTabs = [
    { id: "posts", name: "게시물" },
    { id: "comments", name: "댓글" },
    { id: "about", name: "더보기" },
  ];

  const mentorTabs = [
    { id: "infoShare", name: "정보공유" },
    { id: "povShare", name: "관점공유" },
    { id: "about", name: "더보기" },
  ];

  const adminTabs = [
    { id: "posts", name: "게시물" },
    { id: "comments", name: "댓글" },
    { id: "about", name: "더보기" },
    { id: "adminPriv", name: "관리자" },
  ];

  const manageTabs = [
    { id: "nests", name: "커뮤니티 관리" },
    { id: "users", name: "유저 관리" },
  ];

  if (manageMode) {
    return (
      <div className={styles.tabContainer}>
        {manageTabs.map((tab) => (
          <button
            key={tab.id}
            className={classNames(
              styles.tabButton,
              styles[`${activeTab === tab.id ? "activeTab" : "inactiveTab"}`],
              styles.manageMode
            )}
            onClick={() => handleClick(tab.id)}
          >
            <p>{tab.name}</p>
            <div
              className={classNames(
                styles.highlight,
                activeTab === tab.id ? styles.clicked : styles.hovered
              )}
            ></div>
          </button>
        ))}
      </div>
    );
  }

  switch (role) {
    case "USER":
      return (
        <div className={styles.tabContainer}>
          {userTabs.map((tab) => (
            <button
              key={tab.id}
              className={classNames(
                styles.tabButton,
                styles[`${activeTab === tab.id ? "activeTab" : "inactiveTab"}`]
              )}
              onClick={() => handleClick(tab.id)}
            >
              <p>{tab.name}</p>
              <div
                className={classNames(
                  styles.highlight,
                  activeTab === tab.id ? styles.clicked : styles.hovered
                )}
              ></div>
            </button>
          ))}
        </div>
      );
    case "EXPERT":
      return (
        <div className={styles.tabContainer}>
          {mentorTabs.map((tab) => (
            <button
              key={tab.id}
              className={classNames(
                styles.tabButton,
                styles[`${activeTab === tab.id ? "activeTab" : "inactiveTab"}`]
              )}
              onClick={() => handleClick(tab.id)}
            >
              <p>{tab.name}</p>
              <div
                className={classNames(
                  styles.highlight,
                  activeTab === tab.id ? styles.clicked : styles.hovered
                )}
              ></div>
            </button>
          ))}
        </div>
      );
    case "ADMIN":
      if (isMe) {
        return (
          <div className={styles.tabContainer}>
            {adminTabs.map((tab) => (
              <button
                key={tab.id}
                className={classNames(
                  styles.tabButton,
                  styles[
                    `${activeTab === tab.id ? "activeTab" : "inactiveTab"}`
                  ]
                )}
                onClick={() => handleClick(tab.id)}
              >
                <p>{tab.name}</p>
                <div
                  className={classNames(
                    styles.highlight,
                    activeTab === tab.id ? styles.clicked : styles.hovered
                  )}
                ></div>
              </button>
            ))}
          </div>
        );
      } else {
        return (
          <div className={styles.tabContainer}>
            {adminTabs
              .filter((tab) => tab.id !== "adminPriv")
              .map((tab, idx) => (
                <button
                  key={idx}
                  className={classNames(
                    styles.tabButton,
                    styles[
                      `${activeTab === tab.id ? "activeTab" : "inactiveTab"}`
                    ]
                  )}
                  onClick={() => handleClick(tab.id)}
                >
                  <p>{tab.name}</p>
                  <div
                    className={classNames(
                      styles.highlight,
                      activeTab === tab.id ? styles.clicked : styles.hovered
                    )}
                  ></div>
                </button>
              ))}
          </div>
        );
      }
    default:
      return <Warn variant="big" title="ReadingOption 오류" />;
  }
}

export default ReadingOptions;
