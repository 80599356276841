import pageStyles from "../components/ui/PageBox.module.css";
import styles from "./PolicyPage.module.css";

export function Policy({ notPage = false }) {
  return (
    <div className={notPage && styles.policy_content}>
      <p className={styles.subtitle}>
        주식회사 네스톡(이하 "회사")은 이용자의 개인정보를 보호하고 이와 관련한
        고충을 신속하고 원활하게 처리할 수 있도록 다음과 같이 개인정보
        처리방침을 수립·공개합니다.
      </p>
      <h3 className={styles.title}>제 1조 (개인정보의 처리 목적)</h3>
      <p className={styles.subtitle}>
        회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
        개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이
        변경되는 경우에는「개인정보 보호법」제18조에 따라 별도의 동의를 받는 등
        필요한 조치를 이행할 예정입니다.
      </p>
      <p className={styles.subarticle}>1. 회원 가입 및 관리</p>
      <p className={styles.subarticle}>2. 투자 정보 서비스 제공</p>
      <p className={styles.subarticle}>3. 고객 상담 및 문의 응대</p>
      <p className={styles.subarticle}>4. 마케팅 및 광고 활용</p>
      <p className={styles.subarticle}>5. 서비스 이용 기록 분석 및 통계 작성</p>
      <h3 className={styles.title}>제 2조 (개인정보의 처리 및 보유 기간)</h3>
      <p className={styles.subtitle}>
        회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를
        수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를
        처리·보유합니다.
      </p>
      <p className={styles.subtitle}>1. 회원 가입 및 관리: 회원 탈퇴 시까지</p>
      <p className={styles.article}>
        - 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지
      </p>
      <p className={styles.subarticle}>
        1) 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우
      </p>
      <p className={styles.subarticle}>
        2) 서비스 이용에 따른 채권·채무관계 잔존 시
      </p>
      <p className={styles.subtitle}>2. 관련 법령에 따른 보존 기간</p>
      <p className={styles.article}>
        - 전자상거래 등에서의 소비자 보호에 관한 법률
      </p>
      <p className={styles.subarticle}>
        · 계약 또는 청약철회 등에 관한 기록: 5년
      </p>
      <p className={styles.subarticle}>
        · 대금결제 및 재화 등의 공급에 관한 기록: 5년
      </p>
      <p className={styles.subarticle}>
        · 소비자의 불만 또는 분쟁처리에 관한 기록: 3년
      </p>
      <p className={styles.article}>- 통신비밀보호법</p>
      <p className={styles.subarticle}>· 로그인 기록: 3개월</p>
      <h3 className={styles.title}>제 3조 (처리하는 개인정보의 항목)</h3>
      <p className={styles.subtitle}>
        회사는 다음의 개인정보 항목을 처리하고 있습니다.
      </p>
      <p className={styles.subarticle}>
        1. 필수항목: 이메일 주소, 비밀번호, 닉네임, 이름
      </p>
      <p className={styles.subarticle}>
        2. 선택항목: 프로필 이미지, 관심 투자 분야
      </p>
      <p className={styles.subarticle}>
        3. 자동수집항목: IP 주소, 쿠키, 서비스 이용 기록, 접속 로그
      </p>
      <h3 className={styles.title}>
        제 4조 (만 14세 미만 아동의 개인정보 처리에 관한 사항)
      </h3>
      <p className={styles.subtitle}>
        회사는 만 14세 미만 아동의 개인정보를 처리하지 않습니다.
      </p>
      <h3 className={styles.title}>
        제 5조 (개인정보의 제3자 제공에 관한 사항)
      </h3>
      <p className={styles.subtitle}>
        회사는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위
        내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보
        보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게
        제공합니다.
      </p>
      <h3 className={styles.title}>
        제 6조 (개인정보 처리업무의 위탁에 관한 사항)
      </h3>
      <p className={styles.subtitle}>
        회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를
        위탁하고 있습니다.
      </p>
      <p className={styles.article}>클라우드 서버 호스팅</p>
      <p className={styles.subarticle}>
        - 위탁받는 자 (수탁자): Amazon Web Services Inc.
      </p>
      <p className={styles.subarticle}>
        - 위탁하는 업무의 내용: 클라우드 서버 호스팅 및 관리
      </p>
      <h3 className={styles.title}>
        제 7조 (개인정보의 국외 이전에 관한 사항)
      </h3>
      <p className={styles.subtitle}>
        회사는 데이터 보관 및 처리를 위해 Amazon Web Services(AWS)의 일본 리전
        서버를 이용하고 있어, 개인정보를 국외로 이전하고 있습니다.
      </p>
      <p className={styles.subarticle}>
        - 이전되는 개인정보 항목: 회원 가입 시 수집하는 모든 개인정보
      </p>
      <p className={styles.subarticle}>- 개인정보가 이전되는 국가: 일본</p>
      <p className={styles.subarticle}>
        - 이전 일시 및 방법: 회원 가입 시 실시간으로 서버 전송
      </p>
      <p className={styles.subarticle}>
        - 이전받는 자: Amazon Web Services Inc.
      </p>
      <p className={styles.subarticle}>
        - 이전받는 자의 개인정보 이용목적: 데이터 보관
      </p>
      <p className={styles.subarticle}>
        - 이전받는 자의 보유·이용기간: 회원 탈퇴 시 또는 위탁계약 종료 시까지
      </p>
      <h3 className={styles.title}>제 8조 (개인정보의 파기절차 및 파기방법)</h3>
      <p className={styles.subtitle}>
        회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게
        되었을 때에는 지체없이 해당 개인정보를 파기합니다.
      </p>
      <p className={styles.article}>1. 파기절차</p>
      <p className={styles.subarticle}>
        - 이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져 내부 방침 및
        기타 관련 법령에 따라 일정기간 저장된 후 파기됩니다.
      </p>
      <p className={styles.article}>2. 파기방법</p>
      <p className={styles.subarticle}>
        - 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적
        방법을 사용하여 삭제합니다.
      </p>
      <p className={styles.subarticle}>
        - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
      </p>
      <h3 className={styles.title}>
        제 9조 (정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)
      </h3>
      <p className={styles.subtitle}>
        정보주체는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구
        등의 권리를 행사할 수 있습니다.
      </p>
      <p className={styles.subarticle}>
        1. 권리 행사는 회사에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라
        서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에
        대해 지체 없이 조치하겠습니다.
      </p>
      <p className={styles.subarticle}>
        2. 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을
        통하여 하실 수 있습니다. 이 경우 "개인정보 처리 방법에 관한
        고시(제2020-7호)" 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
      </p>
      <p className={styles.subarticle}>
        3. 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항,
        제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
      </p>
      <p className={styles.subarticle}>
        4. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
        대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
      </p>
      <p className={styles.subarticle}>
        5. 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의
        요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를
        확인합니다.
      </p>
      <h3 className={styles.title}>
        제 10조 (개인정보의 안전성 확보조치에 관한 사항)
      </h3>
      <p className={styles.subtitle}>
        회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
      </p>
      <p className={styles.subarticle}>
        1. 관리적 조치: 내부관리계획 수립·시행, 정기적 직원 교육 등
      </p>
      <p className={styles.subarticle}>
        2. 기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템
        설치, 고유식별정보 등의 암호화, 보안프로그램 설치
      </p>
      <p className={styles.subarticle}>
        3. 물리적 조치: 전산실, 자료보관실 등의 접근통제
      </p>
      <h3 className={styles.title}>
        제 11조 (개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한
        사항)
      </h3>
      <p className={styles.subtitle}>
        회사는 이용자에게 개별적인 맞춤 서비스를 제공하기 위해 이용 정보를
        저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.
      </p>
      <p className={styles.subarticle}>
        1. 쿠키의 사용 목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문
        및 이용형태, 인기 검색어, 보안접속 여부 등을 파악하여 이용자에게
        최적화된 정보 제공을 위해 사용됩니다.
      </p>
      <p className={styles.subarticle}>
        2. 쿠키의 설치·운영 및 거부: 웹브라우저 상단의 도구&gt;인터넷
        옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.
      </p>
      <p className={styles.subarticle}>
        3. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
        있습니다.
      </p>
      <h3 className={styles.title}>
        제 12조 (행태정보의 수집·이용·제공 및 거부 등에 관한 사항)
      </h3>
      <p className={styles.subtitle}>
        회사는 현재 행태정보를 수집하거나 이용하고 있지 않습니다. 향후
        행태정보를 수집·이용·제공할 경우 「개인정보 보호법」 제39조의3에 따라 그
        내용을 본 개인정보 처리방침에 기재하여 공개하도록 하겠습니다.
      </p>
      <h3 className={styles.title}>
        제 13조 (추가적인 이용·제공 관련 판단 기준)
      </h3>
      <p className={styles.subtitle}>
        회사는 「개인정보 보호법」 제15조제3항 및 제17조제4항에 따라 「개인정보
        보호법 시행령」 제14조의2에 따른 사항을 고려하여 정보주체의 동의 없이
        개인정보를 추가적으로 이용·제공할 수 있습니다.
      </p>
      <p className={styles.subarticle}>
        1. 당초 수집 목적과 관련성이 있는지 여부
      </p>
      <p className={styles.subarticle}>
        2. 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인
        이용·제공에 대한 예측 가능성이 있는지 여부
      </p>
      <p className={styles.subarticle}>
        3. 정보주체의 이익을 부당하게 침해하는지 여부
      </p>
      <p className={styles.subarticle}>
        4. 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부
      </p>
      <h3 className={styles.title}>제 14조 (가명정보 처리에 관한 사항)</h3>
      <p className={styles.subtitle}>
        회사는 현재 가명정보를 처리하고 있지 않습니다. 향후 가명정보를 처리할
        경우 「개인정보 보호법」 제28조의4에 따라 다음 사항을 공개하도록
        하겠습니다.
      </p>
      <p className={styles.subarticle}>1. 가명정보의 처리 목적</p>
      <p className={styles.subarticle}>2. 가명정보 처리 및 보유기간</p>
      <p className={styles.subarticle}>3. 가명정보의 제3자 제공에 관한 사항</p>
      <p className={styles.subarticle}>4. 가명정보 처리의 위탁에 관한 사항</p>
      <p className={styles.subarticle}>5. 가명처리하는 개인정보의 항목</p>
      <p className={styles.subarticle}>
        6. 법 제28조의4(가명정보에 대한 안전조치 의무 등)에 따른 가명정보의
        안전성 확보조치에 관한 사항
      </p>
      <h3 className={styles.title}>
        제 15조 (정보주체의 권익침해에 대한 구제방법)
      </h3>
      <p className={styles.subtitle}>
        정보주체는 개인정보침해로 인한 구제를 받기 위하여
        개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
        분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의
        신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
      </p>
      <p className={styles.subarticle}>
        개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
      </p>
      <p className={styles.subarticle}>
        개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
      </p>
      <p className={styles.subarticle}>
        대검찰청 : (국번없이) 1301 (www.spo.go.kr)
      </p>
      <p className={styles.subarticle}>
        경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
      </p>
      <br />
      <p className={styles.subarticle}>
        「개인정보 보호법」 제35조(개인정보의 열람), 제36조(개인정보의
        정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여
        공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를
        받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
      </p>
      <p className={styles.subarticle}>
        ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr)
        홈페이지를 참고하시기 바랍니다.
      </p>
      <h3 className={styles.title}>
        제 16조 (영상정보처리기기 운영·관리에 관한 사항)
      </h3>
      <p className={styles.subtitle}>
        회사는 영상정보처리기기를 운영하지 않고 있습니다.
      </p>
      <h3 className={styles.title}>
        제 17조 (개인정보 처리방침의 변경에 관한 사항)
      </h3>
      <p className={styles.subtitle}>
        이 개인정보 처리방침은 2024년 1월 1일부터 적용됩니다.
      </p>
    </div>
  );
}

export default function PolicyPage() {
  return (
    <div className={pageStyles.pageBox}>
      <h1 className={styles.page_title}>개인정보 처리방침</h1>
      <Policy />
    </div>
  );
}
