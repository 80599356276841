import { useNestock } from "../contexts/NestockProvider";
import { useAuth } from "../contexts/AuthProvider";
import { useEffect, useRef, useState } from "react";
import { register, verify_email } from "../api/AuthApi";
import { Policy } from "../pages/PolicyPage";
import { ReactComponent as ClosedEye } from "../assets/icon--eyes-close.svg";
import { ReactComponent as OpenedEye } from "../assets/icon--eyes-open.svg";
import closeIcon from "../assets/icon--close-button.svg";
import styles from "./Login.module.css";
import classNames from "classnames/bind";

function Login() {
  const { userList } = useNestock();
  const { setLogIn } = useAuth();

  const [isOpen, setIsOpen] = useState(false);

  const modalBackground = useRef();
  const vfcode0 = useRef();
  const vfcode1 = useRef();
  const vfcode2 = useRef();
  const vfcode3 = useRef();
  const vfcode4 = useRef();
  const vfcode5 = useRef();
  const vfcode = [vfcode0, vfcode1, vfcode2, vfcode3, vfcode4, vfcode5];

  const [method, setMethod] = useState("로그인"); //로그인, 약관 동의, 회원가입, 이메일 인증, 비밀번호 찾기, 회원가입 완료
  const [showPw, setShowPw] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);

  const [errorMessage, setErrorMessage] = useState({
    message: "",
  });

  const INITIAL_ERROR_VALUES = {
    nicknameMessage: "",
    emailMessage: "",
    passwordMessage: "",
  };

  const [registerErrorMessage, setRegisterErrorMessage] =
    useState(INITIAL_ERROR_VALUES);

  const INITIAL_LOGIN_VALUES = {
    email: "",
    password: "",
  };

  const INITIAL_REGISTER_VALUES = {
    username: "",
    email: "",
    password: "",
    passwordCheck: "",
  };

  const INITIAL_VERIFY_VALUES = ["", "", "", "", "", ""];

  const [loginValues, setLoginValues] = useState(INITIAL_LOGIN_VALUES);

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    const { code, msg } = await setLogIn(loginValues);
    if (code < 300) {
      setErrorMessage((prevState) => ({
        ...prevState,
        message: "",
      }));
      setLoginValues((prevValues) => ({
        ...prevValues,
        ...INITIAL_LOGIN_VALUES,
      }));
    } else {
      setErrorMessage((prevState) => ({
        ...prevState,
        message: msg,
      }));
    }
    return;
  };

  const handleAgreeSubmit = (e) => {
    e.preventDefault();
    if (!isAgreed) return;
    setMethod("회원가입");
  };

  const [registerValues, setRegisterValues] = useState(INITIAL_REGISTER_VALUES);

  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    setRegisterValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    switch (name) {
      case "username":
        if (userList.map((user) => user.username).includes(value)) {
          setRegisterErrorMessage((prevState) => ({
            ...prevState,
            nicknameMessage: "이미 존재하는 닉네임입니다",
          }));
        } else if (
          ["nestock", "Nestock", "NESTOCK", "네스톡"].includes(value)
        ) {
          setRegisterErrorMessage((prevState) => ({
            ...prevState,
            nicknameMessage: "사용할 수 없는 닉네임입니다",
          }));
        } else if (
          [
            "~",
            "!",
            "@",
            "#",
            "$",
            "%",
            '"',
            ";",
            "'",
            "^",
            ",",
            "&",
            "*",
            "(",
            ")",
            "_",
            "+",
            "|",
            "=",
            "/",
            ">",
            "<",
            "`",
            "?",
            ":",
            "{",
            "[",
            "}",
            "]",
            "/",
          ].some((chrctr) => value.includes(chrctr))
        ) {
          setRegisterErrorMessage((prevState) => ({
            ...prevState,
            nicknameMessage: "닉네임에는 특수문자를 사용할 수 없습니다",
          }));
        } else {
          setRegisterErrorMessage((prevState) => ({
            ...prevState,
            nicknameMessage: "",
          }));
        }
        break;
      case "password":
        if (value !== registerValues.passwordCheck) {
          setRegisterErrorMessage((prevState) => ({
            ...prevState,
            passwordMessage: "비밀번호가 일치하지 않습니다",
          }));
        } else {
          setRegisterErrorMessage((prevState) => ({
            ...prevState,
            passwordMessage: "",
          }));
        }
        break;
      case "passwordCheck":
        if (value !== registerValues.password) {
          setRegisterErrorMessage((prevState) => ({
            ...prevState,
            passwordMessage: "비밀번호가 일치하지 않습니다",
          }));
        } else {
          setRegisterErrorMessage((prevState) => ({
            ...prevState,
            passwordMessage: "",
          }));
        }
        break;
      default: //email
        break;
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    let confirmed = false;
    if (
      !registerErrorMessage.nicknameMessage &&
      !registerErrorMessage.passwordMessage
    ) {
      if (registerValues.username && registerValues.password) {
        confirmed = true;
      }
    }
    if (!confirmed) return;
    const { username, email, password } = registerValues;
    const { code, msg } = await register({ username, email, password });
    if (code < 300) {
      setRegisterErrorMessage((prevState) => ({
        ...prevState,
        ...INITIAL_ERROR_VALUES,
      }));
      setMethod("이메일 인증");
    } else {
      setRegisterErrorMessage((prevState) => ({
        ...prevState,
        emailMessage: msg,
      }));
    }
    return;
  };

  const [verifyValues, setVerifyValues] = useState(INITIAL_VERIFY_VALUES);

  const handleVerifyChange = (e) => {
    const { name, value } = e.target;
    const num = Number(name);

    if (value.length === 1) {
      if (num < 5) {
        vfcode[num + 1].current.focus();
      }
    } else if (value.length === 0) {
      if (num > 0) {
        vfcode[num - 1].current.focus();
      }
    } else {
      e.target.value = value.substr(1);
      if (num < 5) {
        vfcode[num + 1].current.focus();
      }
    }

    setVerifyValues((prevValues) => {
      if (value.length <= 1) {
        return [
          ...prevValues.splice(0, num),
          value,
          ...prevValues.splice(num + 1),
        ];
      } else {
        return [
          ...prevValues.splice(0, num),
          value.substr(1),
          ...prevValues.splice(num + 1),
        ];
      }
    });
  };

  const handleVerifySubmit = async (e) => {
    e.preventDefault();
    const verification_code = verifyValues.reduce((a, b) => a + b);
    const { code, msg } = await verify_email({
      email: registerValues.email,
      verification_code,
    });
    if (code >= 300) {
      setErrorMessage((prevState) => ({
        ...prevState,
        message: JSON.stringify(msg),
      }));
      return;
    }
    setErrorMessage((prevState) => ({ ...prevState, message: "" }));
    setRegisterValues((prevValues) => ({
      ...prevValues,
      ...INITIAL_REGISTER_VALUES,
    }));
    setVerifyValues((prevValues) => ({
      ...prevValues,
      ...INITIAL_VERIFY_VALUES,
    }));
    setMethod("회원가입 완료");
  };

  useEffect(() => {
    if (isOpen) document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  return (
    <>
      <button className={styles.loginButton} onClick={() => setIsOpen(true)}>
        <h2>로그인</h2>
      </button>
      {isOpen && (
        <div
          className={styles.modalContainer}
          ref={modalBackground}
          onClick={(e) => {
            if (e.target === modalBackground.current) {
              setIsOpen(false);
            }
          }}
        >
          <div className={styles.modalContent}>
            <div className={styles.modalHead}>
              <p className={styles.login}>{method}</p>
              <div
                className={styles.closePopup}
                onClick={() => setIsOpen(false)}
              >
                <img src={closeIcon} alt="닫기" />
              </div>
            </div>
            {(() => {
              switch (method) {
                case "로그인":
                  return (
                    <div className={styles.modalBody}>
                      <p>로그인하면 더 많은 기능을 이용하실 수 있어요!</p>
                      <form
                        className={styles.formContainer}
                        onSubmit={handleLoginSubmit}
                      >
                        <div
                          className={classNames(
                            styles.inputContainer,
                            styles.email
                          )}
                        >
                          <label>
                            <div className={styles.inputLabelContainer}>
                              <div className={styles.inputLabel}>이메일</div>
                            </div>
                            <input
                              type="email"
                              name="email"
                              inputMode="email"
                              autoComplete="off"
                              className={styles.input}
                              onChange={handleLoginChange}
                            />
                          </label>
                        </div>
                        <div
                          className={classNames(
                            styles.inputContainer,
                            styles.password
                          )}
                        >
                          <label>
                            <div className={styles.inputLabelContainer}>
                              <div className={styles.inputLabel}>비밀번호</div>
                            </div>
                            <input
                              type={!showPw ? "password" : "text"}
                              name="password"
                              autoComplete="off"
                              className={styles.input}
                              onChange={handleLoginChange}
                            />
                          </label>
                          <div
                            className={styles.showPwToggle}
                            onClick={() => setShowPw(!showPw)}
                          >
                            {!showPw ? <ClosedEye /> : <OpenedEye />}
                          </div>
                        </div>
                        <p className={styles.errorMsg}>
                          {errorMessage.message}
                        </p>
                        <div>
                          <button type="submit" className={styles.submitButton}>
                            <div className={styles.submitLabel}>로그인</div>
                          </button>
                        </div>
                      </form>
                      <div className={styles.signInMenuContainer}>
                        {/* <div className={styles.oauthSignInContainer}>
                          <p>SNS 간편 로그인 / 가입</p>
                          <div
                            className={classNames(
                              styles.iconContainer,
                              styles.google
                            )}
                          ></div>
                          <div
                            className={classNames(
                              styles.iconContainer,
                              styles.kakao
                            )}
                          ></div>
                        </div> */}
                        <div className={styles.alignRight}>
                          <p onClick={() => setMethod("약관 동의")}>
                            이메일로 가입하기
                          </p>
                        </div>
                        {/* <div className={styles.alignRight}>
                          <p onClick={() => setMethod("비밀번호 찾기")}>
                            비밀번호 찾기
                          </p>
                        </div> */}
                      </div>
                    </div>
                  );
                case "약관 동의":
                  return (
                    <div className={styles.terms_container}>
                      <h2>주식회사 네스톡 개인정보 처리방침</h2>
                      <Policy notPage={true} />
                      <div className={styles.agreement_section}>
                        <label className={styles.checkbox_label}>
                          <input
                            type="checkbox"
                            checked={isAgreed}
                            onChange={(e) => setIsAgreed(e.target.checked)}
                          />
                          <span class="checkmark"></span>위 약관에 동의합니다
                        </label>
                      </div>
                      <button
                        className={classNames(
                          styles.agree_button,
                          styles[`${!isAgreed ? "disabled" : ""}`]
                        )}
                        onClick={handleAgreeSubmit}
                        disabled={!isAgreed}
                      >
                        동의하고 계속하기
                      </button>
                      <div className={styles.signInMenuContainer}>
                        <div className={styles.alignRight}>
                          <p onClick={() => setMethod("로그인")}>
                            로그인 화면으로 돌아가기
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                case "회원가입":
                  return (
                    <div
                      className={classNames(styles.modalBody, styles.register)}
                    >
                      <form
                        className={styles.formContainer}
                        onSubmit={handleRegisterSubmit}
                      >
                        <div
                          className={classNames(
                            styles.inputContainer,
                            styles.email
                          )}
                        >
                          <label>
                            <div className={styles.inputLabelContainer}>
                              <div className={styles.inputLabel}>닉네임</div>
                            </div>
                            <input
                              type="text"
                              name="username"
                              autoComplete="off"
                              className={styles.input}
                              onChange={handleRegisterChange}
                            />
                          </label>
                          <p className={styles.errorMsg}>
                            {registerErrorMessage.nicknameMessage}
                          </p>
                        </div>
                        <div
                          className={classNames(
                            styles.inputContainer,
                            styles.email
                          )}
                        >
                          <label>
                            <div className={styles.inputLabelContainer}>
                              <div className={styles.inputLabel}>이메일</div>
                            </div>
                            <input
                              type="text"
                              name="email"
                              inputMode="email"
                              autoComplete="off"
                              className={styles.input}
                              onChange={handleRegisterChange}
                            />
                          </label>
                          <p className={styles.errorMsg}>
                            {registerErrorMessage.emailMessage}
                          </p>
                        </div>
                        <div
                          className={classNames(
                            styles.inputContainer,
                            styles.password
                          )}
                        >
                          <label>
                            <div className={styles.inputLabelContainer}>
                              <div className={styles.inputLabel}>비밀번호</div>
                            </div>
                            <input
                              type="password"
                              name="password"
                              autoComplete="off"
                              className={styles.input}
                              onChange={handleRegisterChange}
                            />
                          </label>
                        </div>
                        <div
                          className={classNames(
                            styles.inputContainer,
                            styles.password
                          )}
                        >
                          <label>
                            <div className={styles.inputLabelContainer}>
                              <div className={styles.inputLabel}>
                                비밀번호 확인
                              </div>
                            </div>
                            <input
                              type="password"
                              name="passwordCheck"
                              autoComplete="off"
                              className={styles.input}
                              onChange={handleRegisterChange}
                            />
                          </label>
                          <p className={styles.errorMsg}>
                            {registerErrorMessage.passwordMessage}
                          </p>
                        </div>
                        <div>
                          <button type="submit" className={styles.submitButton}>
                            <div className={styles.submitLabel}>
                              이메일 인증하기
                            </div>
                          </button>
                        </div>
                      </form>
                      <div className={styles.signInMenuContainer}>
                        <div className={styles.alignRight}>
                          <p onClick={() => setMethod("로그인")}>
                            로그인 화면으로 돌아가기
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                case "이메일 인증":
                  return (
                    <div className={styles.modalBody}>
                      <p>
                        {registerValues.email +
                          "(으)로 인증코드를 전송했습니다"}
                      </p>
                      <form
                        className={styles.formContainer}
                        onSubmit={handleVerifySubmit}
                      >
                        <div className={styles.verticalInput}>
                          <input
                            ref={vfcode0}
                            name="0"
                            type="number"
                            maxLength="1"
                            autoComplete="off"
                            onChange={handleVerifyChange}
                          />
                          <input
                            ref={vfcode1}
                            name="1"
                            type="number"
                            maxLength="1"
                            autoComplete="off"
                            onChange={handleVerifyChange}
                          />
                          <input
                            ref={vfcode2}
                            name="2"
                            type="number"
                            maxLength="1"
                            autoComplete="off"
                            onChange={handleVerifyChange}
                          />
                          <input
                            ref={vfcode3}
                            name="3"
                            type="number"
                            maxLength="1"
                            autoComplete="off"
                            onChange={handleVerifyChange}
                          />
                          <input
                            ref={vfcode4}
                            name="4"
                            type="number"
                            maxLength="1"
                            autoComplete="off"
                            onChange={handleVerifyChange}
                          />
                          <input
                            ref={vfcode5}
                            name="5"
                            type="number"
                            maxLength="1"
                            autoComplete="off"
                            onChange={handleVerifyChange}
                          />
                        </div>
                        <div>
                          <button type="submit" className={styles.submitButton}>
                            <div className={styles.submitLabel}>전송하기</div>
                          </button>
                        </div>
                      </form>
                      <p className={styles.errorMsg}>{errorMessage.message}</p>
                    </div>
                  );
                case "비밀번호 찾기":
                  return <></>;
                case "회원가입 완료":
                  return (
                    <div
                      className={classNames(
                        styles.modalBody,
                        styles.signinSucceed
                      )}
                    >
                      <div className={styles.congratsBox}>
                        <span className={styles.congrats}>
                          &#127881;회원가입을&#127881;
                        </span>
                        <span className={styles.congrats}>완료하셨습니다!</span>
                      </div>
                      <div>
                        <button
                          onClick={() => setMethod("로그인")}
                          className={styles.submitButton}
                        >
                          <div className={styles.submitLabel}>로그인하기</div>
                        </button>
                      </div>
                    </div>
                  );
                default:
                  return <p>오류!</p>;
              }
            })()}
          </div>
        </div>
      )}
    </>
  );
}

export default Login;
