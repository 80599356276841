const base = `${process.env.REACT_APP_API_URL}api/user`;

function settingParams(base, params) {
  let url = `${base}?`;
  for (const dataKey in params) {
    url = url + `${dataKey}=${params[dataKey]}&`;
  }

  return url.slice(0, url.length - 1);
}

function formingData(apiData) {
  const formData = new FormData();
  for (const dataKey in apiData) {
    formData.append(`${dataKey}`, apiData[dataKey]);
  }
  return formData;
}

export async function getUser(userID, token) {
  const res = await fetch(settingParams(base, userID), {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    const { user, post: posts } = data;
    const nextUser = { ...user, posts };
    return { code, msg, nextUser };
  } else {
    return { code, msg, nextUser: null };
  }
}

export async function getListOfUser() {
  const res = await fetch(`${base}/list`, {
    method: "GET",
    credentials: "include",
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function getAllOfUser(token) {
  const res = await fetch(`${base}/all/list`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

// {
//   "target_user_id": "any",
//   "role": "any"
// }

export async function updateUserRole(userData, token) {
  const res = await fetch(`${base}/role`, {
    method: "PUT",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
    body: formingData(userData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}
