import { useAuth } from "../contexts/AuthProvider";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createCommunity } from "../api/CommunityApi";
import NotFoundPage from "./NotFoundPage";
import styles from "./PostingPage.module.css";
import pageStyle from "../components/ui/PageBox.module.css";
import classNames from "classnames/bind";

function CreatingCommunityPage() {
  const { user, token } = useAuth();
  const navigate = useNavigate();

  const INITIAL_VALUES = {
    name: "",
    description: "",
    type: "USER",
  };

  const [values, setValues] = useState(INITIAL_VALUES);

  if (user.id === 0) {
    return (
      <NotFoundPage
        title="커뮤니티를 생성할 수 없습니다"
        description="로그인하시고 이용해주세요"
      />
    );
  }

  const handleSelect = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      type: e.target.value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCreateSubmit = async (e) => {
    e.preventDefault();
    const { code, msg } = await createCommunity(values, token.access_token);
    if (code < 300) {
      alert("요청에 성공했습니다");
      navigate("/");
      window.location.reload();
    } else {
      alert(code, msg);
    }
  };

  return (
    <div className={classNames(styles.container, pageStyle.pageBox)}>
      <h1 className={styles.title}>커뮤니티 생성</h1>
      <form className={styles.form} onSubmit={handleCreateSubmit}>
        {user.role === "ADMIN" && (
          <div className={styles.formGroup}>
            <select
              className={styles.selectType}
              onChange={handleSelect}
              value={values.type}
            >
              {["USER", "EXPERT", "OFFICIAL"].map((item, idx) => (
                <option value={item} key={idx}>
                  {(() => {
                    switch (item) {
                      case "USER":
                        return "유저 커뮤니티";
                      case "EXPERT":
                        return "전문가 커뮤니티";
                      case "OFFICIAL":
                        return "공식 커뮤니티";
                      default:
                        return;
                    }
                  })()}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className={styles.formGroup}>
          <label htmlFor="name" className={styles.label}>
            제목
          </label>
          <input
            type="text"
            name="name"
            className={styles.input}
            autoComplete="off"
            required
            placeholder="제목을 입력하세요"
            onChange={handleChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="description" className={styles.label}>
            설명
          </label>
          <textarea
            name="description"
            className={styles.textarea}
            required
            placeholder="내용을 입력하세요"
            rows="10"
            onChange={handleChange}
          ></textarea>
        </div>
        {/* <div className={styles.formGroup}>
          <label htmlFor="file-upload" className={styles.label}>
            이미지 첨부
          </label>
          <input
            type="file"
            id="file-upload"
            accept="image/*"
            className={styles.fileInput}
          />
          <button
            type="button"
            onClick={() => document.getElementById("file-upload").click()}
            className={styles.fileButton}
            disabled={true}
          >
            이미지 선택
          </button>
          <span className={styles.fileName}>선택된 파일 없음</span>
        </div> */}
        {/* <button type="submit" className={styles.button}>
          임시저장
        </button> */}
        <button type="submit" className={styles.button}>
          요청하기
        </button>
      </form>
    </div>
  );
}

export default CreatingCommunityPage;
