import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import MainPage from "./pages/MainPage";
import SearchResultPage from "./pages/SearchResultPage";
import CreatingCommunityPage from "./pages/CreatingCommunityPage";
import CommunityPage from "./pages/CommunityPage";
// import MentorListPage from "./pages/MentorListPage";
// import MentorPage from "./pages/MentorPage";
import PostPage from "./pages/PostPage";
import PostingPage from "./pages/PostingPage";
import RevisingPostPage from "./pages/RevisingPostPage";
import CalendarPage from "./pages/CalendarPage";
import TradeMemoPage from "./pages/TradeMemoPage";
import CouncelPage from "./pages/CouncelPage";
// import MembershipPage from "./pages/MembershipPage";
import PolicyPage from "./pages/PolicyPage";
import InquiryPage from "./pages/InquiryPage";
import UserPage from "./pages/UserPage";
import NotFoundPage from "./pages/NotFoundPage";
import AdminPage from "./pages/AdminPage";
import ProfileEditPage from "./pages/ProfileEditPage";

function Main() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<MainPage />} />
          <Route path="search" element={<SearchResultPage />} />
          <Route path="posting">
            <Route index element={<PostingPage />} />
            <Route path=":postId" element={<RevisingPostPage />} />
          </Route>
          <Route path="nest">
            <Route index element={<NotFoundPage />} />
            <Route path="create" element={<CreatingCommunityPage />} />
            <Route path=":communitySlug">
              <Route index element={<CommunityPage />} />
              <Route path="post">
                <Route index element={<NotFoundPage />} />
                <Route path=":postId" element={<PostPage />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Route>
          {/* <Route path="mentor">
            <Route index element={<MentorListPage />} />
            <Route path=":mentorSlug">
              <Route index element={<MentorPage />} />
              <Route path="post">
                <Route index element={<NotFoundPage />} />
                <Route path=":postId" element={<PostPage />} />
              </Route>
              <Route path="posting" element={<PostingPage />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Route> */}
          <Route path="calendar" element={<CalendarPage />} />
          <Route path="tradememo" element={<TradeMemoPage />} />
          <Route path="councel" element={<CouncelPage />} />
          <Route path="info">
            {/* <Route path="introduce" element={<IntroducePage />} />
            <Route path="membership" element={<MembershipPage />} />
            <Route path="terms" element={<TermsPage />} /> */}
            <Route path="policy" element={<PolicyPage />} />
            <Route path="inquiry" element={<InquiryPage />} />
          </Route>
          <Route path="user">
            <Route index element={<NotFoundPage />} />
            <Route path=":userID">
              <Route index element={<UserPage />} />
              <Route path="edit" element={<ProfileEditPage />} />
            </Route>
          </Route>
          <Route path="admin" element={<AdminPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Main;
