import { useRef, useState } from "react";
import styles from "./Alarm.module.css";
import alarm from "../assets/icon--alarm.svg";

function Alarm() {
  const [isOpen, setIsOpen] = useState(false);
  const modalBackground = useRef();

  const alarmList = [
    {
      from: "community/stocks-korea",
      content: "김연준님이 좋아요를 눌렀어요",
      date: "2024-10-05T00:00:00.000Z",
    },
    {
      from: "nestock",
      content: "포인트 3000 달성!",
      date: "2024-10-05T00:00:00.000Z",
    },
    {
      from: "community/stocks-foreign",
      content: "최우진님이 댓글을 달았어요",
      date: "2024-10-05T00:00:00.000Z",
    },
    {
      from: "nestock",
      content: "권효준님이 팔로우했어요",
      date: "2024-10-05T00:00:00.000Z",
    },
  ];

  return (
    <>
      <button className={styles.iconButton} onClick={() => setIsOpen(true)}>
        <img src={alarm} alt="알림창" />
      </button>
      {isOpen && (
        <div
          className={styles.modalContainer}
          ref={modalBackground}
          onClick={(e) => {
            if (e.target === modalBackground.current) {
              setIsOpen(false);
            }
          }}
        >
          <div className={styles.modalContent}>
            <h2>알림</h2>
            {alarmList.map((alarm, alarmIndex) => (
              <div key={alarmIndex}>
                <p>{alarm.from}</p>
                <p>{alarm.content}</p>
                <p>{alarm.date}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default Alarm;
