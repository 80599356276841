// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommunityList_innerBox__souL8:not(:last-of-type) {
  border-bottom: var(--border-line);
}

.CommunityList_nest__Z516B {
  margin: 5px 0;
  margin-right: 10px;
  border-radius: 15px;
  padding: 5px 10px;
  padding-right: 0;
}

.CommunityList_nest__Z516B.CommunityList_admin__wjbA9 {
  position: relative;
  padding-bottom: 45px;
}

.CommunityList_nest__Z516B :where(h2, p) {
  cursor: default;
}

.CommunityList_nest__Z516B:hover {
  background-color: rgb(240, 240, 240);
}

.CommunityList_profile__i3QBW {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.CommunityList_communityIMG__dyEou {
  height: 40px;
  border-radius: 23px;
  margin-right: 5px;
}

.CommunityList_buttonBox__KdGX\\+ {
  width: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
}

.CommunityList_buttonBox__KdGX\\+.CommunityList_user__-n6jR {
  width: 240px;
}

.CommunityList_button__dPV2M {
  width: 60px;
  height: 35px;
  padding: 0;
  border-radius: 10px;
  background-color: rgb(220, 220, 220);
  display: flex;
  justify-content: center;
  align-items: center;
}

.CommunityList_button__dPV2M.CommunityList_user__-n6jR {
  width: 110px;
}

.CommunityList_button__dPV2M:hover {
  background-color: rgb(200, 200, 200);
}

.CommunityList_button__dPV2M > p {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/CommunityList.module.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,UAAU;EACV,mBAAmB;EACnB,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,SAAS;AACX","sourcesContent":[".innerBox:not(:last-of-type) {\n  border-bottom: var(--border-line);\n}\n\n.nest {\n  margin: 5px 0;\n  margin-right: 10px;\n  border-radius: 15px;\n  padding: 5px 10px;\n  padding-right: 0;\n}\n\n.nest.admin {\n  position: relative;\n  padding-bottom: 45px;\n}\n\n.nest :where(h2, p) {\n  cursor: default;\n}\n\n.nest:hover {\n  background-color: rgb(240, 240, 240);\n}\n\n.profile {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  position: relative;\n}\n\n.communityIMG {\n  height: 40px;\n  border-radius: 23px;\n  margin-right: 5px;\n}\n\n.buttonBox {\n  width: 140px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  position: absolute;\n}\n\n.buttonBox.user {\n  width: 240px;\n}\n\n.button {\n  width: 60px;\n  height: 35px;\n  padding: 0;\n  border-radius: 10px;\n  background-color: rgb(220, 220, 220);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.button.user {\n  width: 110px;\n}\n\n.button:hover {\n  background-color: rgb(200, 200, 200);\n}\n\n.button > p {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerBox": `CommunityList_innerBox__souL8`,
	"nest": `CommunityList_nest__Z516B`,
	"admin": `CommunityList_admin__wjbA9`,
	"profile": `CommunityList_profile__i3QBW`,
	"communityIMG": `CommunityList_communityIMG__dyEou`,
	"buttonBox": `CommunityList_buttonBox__KdGX+`,
	"user": `CommunityList_user__-n6jR`,
	"button": `CommunityList_button__dPV2M`
};
export default ___CSS_LOADER_EXPORT___;
