import { useNavigate } from "react-router-dom";
import pf from "../assets/logo.png";
import Korea from "../assets/Korea.svg";
import USA from "../assets/USA.svg";
import styles from "./CommunityList.module.css";
import classNames from "classnames";

function CommunityList({ nests, handleFunctions = {}, manageMode = false }) {
  const navigate = useNavigate();
  const { handleApprove = null, handleDelete = null } = handleFunctions;
  return (
    <>
      {nests?.map((nest) => {
        let pfImg;
        if (nest.name === "국내주식") {
          pfImg = Korea;
        } else if (nest.name === "미국주식") {
          pfImg = USA;
        } else {
          pfImg = pf;
        }
        const isPending = nest.status === "pending";
        const handleApproveClick = async (e) => {
          e.stopPropagation();
          const confirmed = window.confirm("요청을 승인하시겠습니까?");
          if (!confirmed) return;
          const { code, msg } = await handleApprove(
            { community_id: nest.id },
            manageMode?.token
          );
          if (code < 300) {
            alert("커뮤니티 생성 요청이 승인되었습니다");
            window.location.reload();
          } else {
            alert(msg);
          }
        };
        const handleRefuseClick = async (e) => {
          e.stopPropagation();
          const confirmed = window.confirm("요청을 거절하시겠습니까?");
          if (!confirmed) return;
          const { code, msg } = await handleDelete(
            { community_id: nest.id },
            manageMode?.token
          );
          if (code < 300) {
            alert("커뮤니티 생성 요청이 거절되었습니다");
            window.location.reload();
          } else {
            alert(msg);
          }
        };
        const handleDeleteClick = async (e) => {
          e.stopPropagation();
          const confirmed = window.confirm("해당 커뮤니티를 삭제하시겠습니까?");
          if (!confirmed) return;
          const code = await handleDelete(
            { community_id: nest.id },
            manageMode?.token
          );
          if (code < 300) {
            alert(`${nest.name} 커뮤니티를 삭제했습니다`);
            window.location.reload();
          } else {
            alert("실패했습니다");
          }
        };
        return (
          <div key={nest.id} className={styles.innerBox}>
            <div
              className={classNames(styles.nest, manageMode && styles.admin)}
              onClick={() => navigate(`/nest/${nest.id}`)}
            >
              <div className={styles.profile}>
                <img
                  src={pfImg}
                  className={styles.communityIMG}
                  alt="커뮤니티 이미지"
                />
                <h2>{`${nest.name} (${(() => {
                  switch (nest.type) {
                    case "USER":
                      return "유저 커뮤니티";
                    case "EXPERT":
                      return "전문가 커뮤니티";
                    case "OFFICIAL":
                      return "공식 커뮤니티";
                    default:
                      return;
                  }
                })()})`}</h2>
              </div>
              <p>{nest.description}</p>
              {manageMode &&
                (isPending ? (
                  <div className={styles.buttonBox}>
                    <button
                      className={styles.button}
                      onClick={handleApproveClick}
                    >
                      <p>승인</p>
                    </button>
                    <button
                      className={styles.button}
                      onClick={handleRefuseClick}
                    >
                      <p>거절</p>
                    </button>
                  </div>
                ) : (
                  <div className={styles.buttonBox}>
                    <button
                      className={styles.button}
                      onClick={handleDeleteClick}
                    >
                      <p>삭제</p>
                    </button>
                  </div>
                ))}
            </div>
          </div>
        );
      })}
    </>
  );
}

export default CommunityList;
