import { useNestock } from "../contexts/NestockProvider";
import { useAuth } from "../contexts/AuthProvider";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { updatePost } from "../api/PostApi";
import styles from "./PostingPage.module.css";
import pageStyle from "../components/ui/PageBox.module.css";
import classNames from "classnames/bind";
import NotFoundPage from "./NotFoundPage";

function RevisingPostPage() {
  const { updateListOfPost } = useNestock();
  const { user, token: access_token } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const community_id = Number(location.pathname.split("/")[2]);
  const post_id = Number(location.pathname.split("/")[4]);

  const {
    user_id,
    comments,
    title: initial_title,
    content: initial_content,
  } = location.state;

  const INITIAL_VALUES = {
    post_id,
    title: initial_title,
    content: initial_content,
  };

  const [values, setValues] = useState(INITIAL_VALUES);

  if (user.id !== user_id) {
    return <NotFoundPage title="게시물을 수정할 수 없습니다" />;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleReviseSubmit = async (e) => {
    e.preventDefault();
    const { code, msg, data } = await updatePost(values, access_token);
    if (code < 300) {
      updateListOfPost("update", data, comments);
      navigate(`/nest/${community_id}/post/${post_id}`);
    } else {
      alert(msg);
    }
  };

  return (
    <div className={classNames(styles.container, pageStyle.pageBox)}>
      <h1 className={styles.title}>게시물 수정</h1>
      <form className={styles.form} onSubmit={handleReviseSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="title" className={styles.label}>
            제목
          </label>
          <input
            type="text"
            name="title"
            className={styles.input}
            required
            placeholder="제목을 입력하세요"
            value={values.title}
            onChange={handleChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="content" className={styles.label}>
            내용
          </label>
          <textarea
            name="content"
            className={styles.textarea}
            required
            placeholder="내용을 입력하세요"
            rows="10"
            value={values.content}
            onChange={handleChange}
          ></textarea>
        </div>
        {/* <div className={styles.formGroup}>
          <label htmlFor="file-upload" className={styles.label}>
            이미지 첨부
          </label>
          <input
            type="file"
            id="file-upload"
            accept="image/*"
            className={styles.fileInput}
          />
          <button
            type="button"
            onClick={() => document.getElementById("file-upload").click()}
            className={styles.fileButton}
            disabled={true}
          >
            이미지 선택
          </button>
          <span className={styles.fileName}>선택된 파일 없음</span>
        </div> */}
        {/* <button type="submit" className={styles.button}>
          임시저장
        </button> */}
        <button type="submit" className={styles.button}>
          업로드
        </button>
      </form>
    </div>
  );
}

export default RevisingPostPage;
