// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RightSideBar_edge__j1N0v {
  height: 50vh;
  border-radius: 25px;
  padding: 15px;
  margin-top: 6vh;
  /* background-color: rgb(238, 241, 238);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
}

@media screen and (max-width: 960px) {
  .RightSideBar_edge__j1N0v {
    display: none;
  }
}

@media screen and (min-width: 960px) and (max-width: 1200px) {
  .RightSideBar_edge__j1N0v {
    display: initial;
    width: 25vw;
  }
}

@media screen and (min-width: 1200px) {
  .RightSideBar_edge__j1N0v {
    display: initial;
    width: 20vw;
  }
}

/* .sideBar {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.sideBar * {
  text-align: center;
  font-size: 18px;
}

.link {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.link > h1 {
  width: fit-content;
  margin-left: 10px;
} */
`, "",{"version":3,"sources":["webpack://./src/components/RightSideBar.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf;6EAC2E;AAC7E;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,gBAAgB;IAChB,WAAW;EACb;AACF;;AAEA;EACE;IACE,gBAAgB;IAChB,WAAW;EACb;AACF;;AAEA;;;;;;;;;;;;;;;;;;;;;GAqBG","sourcesContent":[".edge {\n  height: 50vh;\n  border-radius: 25px;\n  padding: 15px;\n  margin-top: 6vh;\n  /* background-color: rgb(238, 241, 238);\n  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */\n}\n\n@media screen and (max-width: 960px) {\n  .edge {\n    display: none;\n  }\n}\n\n@media screen and (min-width: 960px) and (max-width: 1200px) {\n  .edge {\n    display: initial;\n    width: 25vw;\n  }\n}\n\n@media screen and (min-width: 1200px) {\n  .edge {\n    display: initial;\n    width: 20vw;\n  }\n}\n\n/* .sideBar {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n}\n\n.sideBar * {\n  text-align: center;\n  font-size: 18px;\n}\n\n.link {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n\n.link > h1 {\n  width: fit-content;\n  margin-left: 10px;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"edge": `RightSideBar_edge__j1N0v`
};
export default ___CSS_LOADER_EXPORT___;
