import ShowPost from "./ShowPost";
import styles from "./PostList.module.css";

function PostList({ showOnlyUser, posts = [], handleLoadMore }) {
  return (
    <>
      {posts.map((post) => {
        return (
          <div key={post.id} className={styles.innerBox}>
            <div className={styles.post}>
              <ShowPost
                isOnList={true}
                value={post}
                showOnlyUser={showOnlyUser}
              />
            </div>
          </div>
        );
      })}
      {handleLoadMore && (
        <button onClick={handleLoadMore}>
          <h2>더보기</h2>
        </button>
      )}
    </>
  );
}

export default PostList;
