import { createContext, useContext, useEffect, useState } from "react";
import { getCommunity } from "../api/CommunityApi";
import { getListOfPost, getListOfCommentOnPost } from "../api/PostApi";
import { getListOfUser } from "../api/UserApi";

const NestockContext = createContext();

function NestockProvider({ children }) {
  const [userList, setUserList] = useState();
  const [communityList, setCommunityList] = useState();
  const [requestPage, setRequestPage] = useState(1);
  const [restCommunities, setRestCommunities] = useState();
  const [hottestList, setHottestList] = useState([]);
  const [newestList, setNewestList] = useState([]);
  const [postList, setPostList] = useState([]);
  const [commentList, setCommentList] = useState();
  const [loading, setLoading] = useState(true);

  const refreshUserList = async () => {
    const { code, msg, data } = await getListOfUser();
    if (data) {
      setUserList(data);
      return data;
    } else {
      console.log(code, msg);
    }
  };

  const updateListOfPost = (type, updatedPost, updatedComments) => {
    const theUser = userList.find((user) => user.id === updatedPost.user_id);
    const theCommunity = communityList.find(
      (commu) => commu.id === updatedPost.community_id
    );
    const formalPost =
      theUser && theCommunity
        ? {
            ...updatedPost,
            comments: updatedComments,
            User: {
              user_id: theUser.id,
              username: theUser.username,
            },
            Community: {
              community_id: theCommunity.id,
              communityTitle: theCommunity.name,
            },
          }
        : {
            ...updatedPost,
            comments: updatedComments,
          };
    if (type === "create") {
      setNewestList((prevList) => [formalPost, ...prevList]);
    } else if (type === "delete") {
      setHottestList((prevList) =>
        prevList.filter((post) => post.id !== updatedPost.id)
      );
      setNewestList((prevList) =>
        prevList.filter((post) => post.id !== updatedPost.id)
      );
      setPostList((prevList) =>
        prevList.filter((post) => post.id !== updatedPost.id)
      );
    } else {
      const replaceIdx1 = hottestList.findIndex(
        (post) => post.id === updatedPost.id
      );
      const replaceIdx2 = newestList.findIndex(
        (post) => post.id === updatedPost.id
      );
      const replaceIdx3 = postList.findIndex(
        (post) => post.id === updatedPost.id
      );
      if (replaceIdx1 >= 0) {
        setHottestList((prevList) => [
          ...prevList.slice(0, replaceIdx1),
          formalPost,
          ...prevList.slice(replaceIdx1 + 1),
        ]);
      }
      if (replaceIdx2 >= 0) {
        setNewestList((prevList) => [
          ...prevList.slice(0, replaceIdx2),
          formalPost,
          ...prevList.slice(replaceIdx2 + 1),
        ]);
      }
      if (replaceIdx3 >= 0) {
        setPostList((prevList) => [
          ...prevList.slice(0, replaceIdx3),
          formalPost,
          ...prevList.slice(replaceIdx3 + 1),
        ]);
      }
    }
  };

  const handleRequestPosts = async (communities, users) => {
    if (restCommunities?.length === 0) {
      alert("모든 게시물을 열람하셨습니다");
      return;
    }
    const commus = communities && communities.map((commu) => commu.id);
    const pendingPostList = commus
      ? await Promise.all(
          commus.map((community_id) =>
            getListOfPost({
              community_id,
              page: requestPage,
              page_size: 20,
            })
          )
        )
      : await Promise.all(
          restCommunities.map((community_id) =>
            getListOfPost({
              community_id,
              page: requestPage,
              page_size: 20,
            })
          )
        );
    setRequestPage((prevPage) => prevPage + 1);
    const flattenedPosts = pendingPostList
      .map((res, idx) => ({
        community_id: commus ? commus[idx] : restCommunities[idx],
        res,
      }))
      .flatMap((post) => {
        const {
          community_id,
          res: { code, msg, data },
        } = post;
        if (code < 300) {
          if (data.length < 20) {
            setRestCommunities((prevList) => {
              if (commus) {
                const deleteIdx = commus.findIndex((id) => id === community_id);
                return commus.splice(deleteIdx, 1);
              } else {
                return prevList.filter((id) => id !== community_id);
              }
            });
          }
        } else {
          console.log(community_id, code, msg);
        }
        return data.map((post) => {
          const {
            User: { username },
            ...rest
          } = post;
          return {
            ...rest,
            User: {
              user_id: users
                ? users.find((user) => user.username === username).id
                : userList.find((user) => user.username === username).id,
              username,
            },
            Community: {
              community_id,
              communityTitle: communities
                ? communities.find((commu) => commu.id === community_id).name
                : communityList.find((commu) => commu.id === community_id).name,
            },
          };
        });
      });
    async function getComments() {
      let page = 1;
      let commentList_final = [];
      let restPosts = flattenedPosts.map(({ id }) => id);
      do {
        const commentList_1 = await Promise.all(
          restPosts.map((post_id) =>
            getListOfCommentOnPost({
              post_id,
              page,
              page_size: 20,
            })
          )
        );
        const commentList_2 = commentList_1
          .map((res, idx) => ({ post_id: restPosts[idx], res }))
          .map(({ post_id, res: { code, msg, data } }) => {
            if (code < 300) {
              if (data.length < 20) {
                // 20은 page_size
                const deleteIdx = restPosts.findIndex((id) => id === post_id);
                restPosts.splice(deleteIdx, 1);
              }
            } else {
              console.log(post_id, code, msg);
            }
            return {
              post_id,
              commentListData: data
                .map(({ user_id, ...rest }) => ({
                  ...rest,
                  user_id,
                  username: users.find((user) => user.id === user_id).username,
                }))
                .reverse(),
            };
          });
        commentList_2.forEach((commentData) => {
          const splitIdx = commentList_final.findIndex(
            (data) => data?.post_id === commentData.post_id
          );
          if (splitIdx < 0) {
            //findIndex는 실패하면 -1을 반환하기 때문에 반복문의 맨 처음에 실행할 함수를 작성
            commentList_final.push(commentData);
          } else {
            const origin = commentList_final[splitIdx];
            commentList_final.splice(splitIdx, 1, {
              ...origin,
              commentListData: [
                ...commentData.commentListData,
                ...origin.commentListData,
              ],
            });
          }
        });
        page += 1;
      } while (restPosts.length); //posts.length의 값이 0이 되기 전까지 반복
      return commentList_final;
    }
    const commentList = await getComments();
    let postList_final = flattenedPosts
      .map((post) => {
        const idx = commentList.findIndex((comments) => {
          return comments.post_id === post.id;
        });
        const comments = commentList.splice(idx, 1)[0].commentListData;
        return { ...post, comments };
      })
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      )
      .sort((a, b) => {
        const likesFigure = Number(b.likes) - Number(a.likes);
        const commentsFigure = b.comments.length - a.comments.length;
        // const viewCountFigure = b.view_count - a.view_count;
        const popularity = likesFigure + commentsFigure;
        if (popularity) {
          return popularity;
        } else {
          return likesFigure;
        }
      });
    setHottestList((prevList) => [...prevList, ...postList_final]);
    setNewestList((prevList) => {
      const newList = postList_final.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
      return [...prevList, ...newList];
    });
    setRestCommunities(commus);
  };

  useEffect(() => {
    async function getNestock() {
      try {
        setLoading(true);
        const {
          code: communityCode,
          msg: communityMsg,
          data: communityData,
        } = await getCommunity();

        if (communityData) {
          setCommunityList(communityData);
        } else {
          alert(communityCode, communityMsg);
          return;
        }

        const {
          code: userCode,
          msg: userMsg,
          data: userData,
        } = await getListOfUser();
        if (userData) {
          setUserList(userData);
        } else {
          console.log(userCode, userMsg);
        }

        await handleRequestPosts(communityData, userData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    getNestock();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // 또는 로딩 컴포넌트
  }

  return (
    <NestockContext.Provider
      value={{
        userList,
        refreshUserList,
        communityList,
        hottestList,
        newestList,
        handleRequestPosts,
        postList,
        updateListOfPost,
        setPostList,
        commentList,
        setCommentList,
      }}
    >
      {children}
    </NestockContext.Provider>
  );
}

function useNestock() {
  const context = useContext(NestockContext);

  if (!context) {
    throw new Error("반드시 AuthProvider 안에서 사용해야 합니다.");
  }

  return context;
}

export { NestockProvider, useNestock };
