import { useAuth } from "../contexts/AuthProvider";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import LeftSideBar from "./LeftSideBar";
import SearchBar from "./SearchBar";
import { ReactComponent as Person } from "../assets/person.svg";
import Alarm from "./Alarm";
import Login from "./Login";
import logo from "../assets/logo.svg";
import logoName from "../assets/logo-name.svg";
import styles from "./Nav.module.css";
import { ReactComponent as User } from "../assets/icon--user.svg";
import { ReactComponent as Calendar } from "../assets/icon--calendar.svg";
import { ReactComponent as LogOut } from "../assets/icon--logout.svg";
import dropdownStyles from "./ProfileDropdown.module.css";

function Nav() {
  const {
    user,
    token: { access_token },
    isOnline,
    setLogOut,
  } = useAuth();
  const [leftOpen, setLeftOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const leftBar = useRef();
  const userMenu = useRef();
  const navigate = useNavigate();
  return (
    <div className={styles.nav}>
      <div className={styles.navContainer}>
        <div className={styles.navBody}>
          {leftOpen && (
            <div
              className={styles.leftContainer}
              ref={leftBar}
              onClick={(e) => {
                e.stopPropagation();
                if (e.target === leftBar.current) {
                  setLeftOpen(false);
                }
              }}
            >
              <LeftSideBar isOpen={leftOpen} />
            </div>
          )}
          <div
            className={styles.leftBarIcon}
            onClick={() => {
              setUserOpen(false);
              setLeftOpen(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="30"
              height="30"
            >
              <path
                d="M3 6h18v2H3V6zm0 5h18v2H3v-2zm0 5h18v2H3v-2z"
                fill="#000000"
              />
            </svg>
          </div>
          <img
            className={styles.mobileLogo}
            onClick={() => navigate("/")}
            src={logo}
            alt="Nestock Logo"
          />
          <img
            className={styles.notMobileLogo}
            onClick={() => navigate("/")}
            src={logoName}
            alt="Nestock Logo"
          />
        </div>
        <SearchBar className={styles.searchBar} />
        <div className={styles.menuBox}>
          {isOnline ? (
            <>
              <div
                className={styles.iconButton}
                onClick={() => {
                  setLeftOpen(false);
                  setUserOpen(!userOpen);
                }}
              >
                <Person />
                {userOpen && (
                  <div
                    className={styles.userContainer}
                    ref={userMenu}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (e.target === userMenu.current) {
                        setUserOpen(false);
                      }
                    }}
                  >
                    <div className={dropdownStyles.dropdownMenu}>
                      <div className={dropdownStyles.dropdownContent}>
                        <div className={dropdownStyles.userInfo}>
                          <p className={dropdownStyles.userName}>
                            {user.username}
                          </p>
                          <p className={dropdownStyles.userEmail}>
                            {user.email}
                          </p>
                        </div>
                        <div
                          onClick={() => {
                            setUserOpen(false);
                            navigate(`/user/${user.id}`);
                          }}
                          className={dropdownStyles.menuItem}
                        >
                          <User
                            className={dropdownStyles.smallIcon}
                            style={{ color: "#f97316" }}
                          />
                          프로필
                        </div>
                        <div
                          className={dropdownStyles.menuItem}
                          onClick={() => {
                            setUserOpen(false);
                            navigate("/calendar");
                          }}
                        >
                          <Calendar
                            className={dropdownStyles.smallIcon}
                            style={{ color: "#f97316" }}
                          />
                          주식 캘린더
                        </div>
                        <div className={dropdownStyles.menuDivider}>
                          <button
                            onClick={() => {
                              setUserOpen(false);
                              setLogOut(access_token);
                            }}
                            className={dropdownStyles.logoutButton}
                          >
                            <LogOut className={dropdownStyles.smallIcon} />
                            로그아웃
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <div className={styles.userMenu}>
                      <div
                        className={styles.userProfile}
                        onClick={() => {
                          setUserOpen(false);
                          navigate(`/user/${user.id}`);
                        }}
                      >
                        <h2>프로필</h2>
                      </div>
                      <div
                        className={styles.stockCalendar}
                        onClick={() => {
                          setUserOpen(false);
                          navigate("/calendar");
                        }}
                      >
                        <h2>주식 캘린더</h2>
                      </div>
                      <div
                        className={styles.logOut}
                        onClick={() => {
                          setUserOpen(false);
                          setLogOut(access_token);
                        }}
                      >
                        <h2>로그아웃</h2>
                      </div>
                    </div> */}
                  </div>
                )}
              </div>
              <Alarm />
            </>
          ) : (
            <Login />
          )}
        </div>
      </div>
    </div>
  );
}

export default Nav;
