import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Plus } from "../assets/icon--plus.svg";
import { ReactComponent as Recent } from "../assets/icon--recent.svg";
import person from "../assets/person.png";
import logo from "../assets/logo.png";
import { ReactComponent as HotCoummnity } from "../assets/icon--fire.svg";
import { ReactComponent as Info } from "../assets/icon--info.svg";
import { ReactComponent as Close } from "../assets/icon--close-button.svg";
import styles from "./LeftSideBar.module.css";
import classNames from "classnames";

function LeftSideBar({ isOpen }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [visitHistory, setVisitHistory] = useState(() => {
    const historyData = localStorage.getItem("visitHistory");
    return historyData ? JSON.parse(historyData) : [];
  });

  const followingUsers = [
    {
      id: 1,
      name: "시그비트",
      avatar: person,
    },
    {
      id: 2,
      name: "Jaysontatum",
      avatar: person,
    },
    {
      id: 3,
      name: "cristiano",
      avatar: person,
    },
    {
      id: 4,
      name: "플라워댄스",
      avatar: person,
    },
    {
      id: 5,
      name: "개발자",
      avatar: person,
    },
  ];

  const handleDeleteHistory = (id) => {
    setVisitHistory((prevHistory) =>
      prevHistory.filter((his) => his.id !== id)
    );
    localStorage.setItem(
      "visitHistory",
      JSON.stringify(visitHistory.filter((his) => his.id !== id))
    );
  };

  useEffect(() => {
    setVisitHistory(() => {
      const historyData = localStorage.getItem("visitHistory");
      const nextHistory = historyData ? JSON.parse(historyData) : [];
      return nextHistory;
    });
  }, [location.pathname]);
  return (
    <div
      id={styles.toggleMotion}
      className={classNames(
        styles.edge,
        styles[`${isOpen ? "toggleContainer" : "notToggleContainer"}`]
      )}
    >
      <div className={styles.sideBar}>
        <div
          className={classNames(styles.content, styles.button)}
          onClick={() => navigate("/nest/create")}
        >
          <div className={styles.title}>
            <div className={styles.icon}>
              <Plus color="#000000" />
            </div>
            <h3>커뮤니티 생성</h3>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            <div className={styles.icon}>
              <Recent />
            </div>
            <h3>최근 방문</h3>
          </div>
          <div
            className={classNames(
              styles.list,
              visitHistory.length > 0 && styles.communities
            )}
          >
            {visitHistory.length > 0 ? (
              visitHistory.map((history, i) => (
                <div key={i} className={styles.listItem}>
                  <div
                    className={styles.itemInfo}
                    onClick={() => navigate(`/nest/${history.id}`)}
                  >
                    <img
                      src={logo}
                      alt={`${history.name} 프로필`}
                      className={styles.userAvatar}
                    />
                    <p className={styles.userName}>{history.name} 커뮤니티</p>
                  </div>
                  <button
                    className={styles.closeButton}
                    onClick={() => handleDeleteHistory(history.id)}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 6L14 14M14 6L6 14"
                        stroke="#9CA3AF"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              ))
            ) : (
              <p>방문하신 커뮤니티가 없습니다</p>
            )}
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            {/* <div className={styles.icon}>
              </>
            </div> */}
            <h3>팔로잉</h3>
          </div>
          <div className={classNames(styles.list, styles.followers)}>
            {followingUsers.map((user) => (
              <div key={user.id} className={styles.followingItem}>
                <img
                  src={user.avatar}
                  alt={`${user.name} 프로필`}
                  className={styles.userAvatar}
                />
                <div className={styles.userName}>{user.name}</div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            <div className={styles.icon}>
              <HotCoummnity />
            </div>
            <h3>인기 커뮤니티</h3>
          </div>
          <div className={styles.list}>
            <p>운영 준비중인 컨텐츠입니다</p>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            <div className={styles.icon}>
              <Info />
            </div>
            <h3>사이트 정보</h3>
          </div>
          <div className={styles.list}>
            <p>nestock 소개</p>
            <p>멤버쉽 소개</p>
            <p>사용자 이용 약관</p>
            <div onClick={() => navigate("/info/policy")}>
              <p>개인정보 처리방침</p>
            </div>
            <p>문의하기</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftSideBar;
