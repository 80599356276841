import { useNestock } from "../contexts/NestockProvider";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CommunityList from "../components/CommunityList";
import PostList from "../components/PostList";
import { ReactComponent as Plus } from "../assets/icon--plus.svg";
import pageStyles from "../components/ui/PageBox.module.css";
import styles from "./MainPage.module.css";
import classNames from "classnames";

function MainPage() {
  const {
    communityList = [],
    hottestList = [],
    newestList = [],
    handleRequestPosts,
  } = useNestock();
  const [sortOption, setSortOption] = useState("인기순");
  const navigate = useNavigate();
  return (
    <div className={pageStyles.pageBox}>
      <div className={styles.buttonBox}>
        <div className={styles.listButtonBox}>
          <button
            className={classNames(
              styles.sortButton,
              sortOption === "인기순" ? styles.selected : false
            )}
            onClick={() => setSortOption("인기순")}
          >
            <h4>인기순</h4>
          </button>
          <button
            className={classNames(
              styles.sortButton,
              sortOption === "최신순" ? styles.selected : false
            )}
            onClick={() => setSortOption("최신순")}
          >
            <h4>최신순</h4>
          </button>
          <button
            className={classNames(
              styles.sortButton,
              sortOption === "커뮤니티 목록" ? styles.selected : false
            )}
            onClick={() => setSortOption("커뮤니티 목록")}
          >
            <h4>커뮤니티 목록</h4>
          </button>
        </div>
        <button
          className={styles.createButton}
          onClick={() => navigate("/posting")}
        >
          <Plus width="18" height="18" color="#000000" />
          <h4 className={styles.notMobile}>포스팅</h4>
        </button>
      </div>
      {sortOption === "커뮤니티 목록" ? (
        <CommunityList nests={communityList} />
      ) : (
        <>
          <PostList
            showOnlyUser={false}
            posts={sortOption === "인기순" ? hottestList : newestList}
            handleLoadMore={handleRequestPosts}
          />
        </>
      )}
    </div>
  );
}

export default MainPage;
