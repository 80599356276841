import { useRef, useState, useEffect } from "react";
import { ReactComponent as Close } from "../assets/icon--close-button.svg";
import { ReactComponent as Image } from "../assets/icon--image.svg";
import styles from "./FileInput.module.css";

function FileInput({ value, onChange }) {
  const [preview, setPreview] = useState(null);
  const inputRef = useRef();

  const handleChange = (e) => {
    const nextValue = e.target.files[0];
    onChange(nextValue);
  };

  const handleClearClick = () => {
    const inputNode = inputRef.current;
    if (!inputNode) return;

    inputNode.value = "";
    onChange(null);
  };

  useEffect(() => {
    if (!value) return;

    const nextPreview = URL.createObjectURL(value);
    setPreview(nextPreview);

    return () => {
      setPreview(null);
      URL.revokeObjectURL(nextPreview);
    };
  }, [value]);

  return (
    <div className={styles.fileInput}>
      {preview && (
        <div className={styles.imgBox}>
          <img className={styles.preview} src={preview} alt="이미지 미리보기" />
          <div className={styles.closeButton} onClick={handleClearClick}>
            <Close width="15" height="15" />
          </div>
        </div>
      )}
      <input
        type="file"
        accept="image/png, image/jpeg"
        className={styles.imageInput}
        onChange={handleChange}
        ref={inputRef}
      />
      <button
        type="button"
        className={styles.imageButton}
        onClick={() => inputRef.current.click()}
      >
        <Image />
      </button>
    </div>
  );
}

export default FileInput;
