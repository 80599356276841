import { useNestock } from "../contexts/NestockProvider";
import { useAuth } from "../contexts/AuthProvider";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  approvePendingPost,
  createPost,
  createImageOnPost,
} from "../api/PostApi";
import NotFoundPage from "./NotFoundPage";
import FileInput from "../components/FileInput";
import styles from "./PostingPage.module.css";
import pageStyle from "../components/ui/PageBox.module.css";
import classNames from "classnames/bind";

function PostingPage() {
  const { communityList, updateListOfPost } = useNestock();
  const { user, token } = useAuth();
  const community = useLocation().state;
  const communities = communityList.map(({ id, name }) => ({
    id,
    name,
  }));
  communities.unshift({ id: -1, name: "커뮤니티" });
  const filteredCommunities =
    user.role !== "ADMIN"
      ? communities
      : communities.filter((commu) => commu.name !== "공지"); // 공지 커뮤니티 제외시킴
  const navigate = useNavigate();

  const INITIAL_VALUES = {
    title: "",
    content: "",
    community_id: community ? community.community_id : -1,
  };

  const INITIAL_IMAGE_VALUES = {
    image: null,
  };

  const [values, setValues] = useState(INITIAL_VALUES);
  const [imgValue, setImgValue] = useState(INITIAL_IMAGE_VALUES);

  if (user.id === 0) {
    return (
      <NotFoundPage
        title="게시물을 작성할 수 없습니다"
        description="로그인하시고 이용해주세요"
      />
    );
  }

  const handleSelect = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      community_id: e.target.value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleImageChange = (value) => {
    setImgValue((prevValues) => ({
      ...prevValues,
      image: value,
    }));
  };

  const handleUploadSubmit = async (e) => {
    e.preventDefault();
    if (values.community_id === -1) {
      alert("업로드할 커뮤니티를 선택해주세요");
      return;
    }
    const {
      code: saveCode,
      msg: saveMsg,
      data: saveData,
    } = await createPost(values, token.access_token);
    if (saveCode < 300) {
      if (imgValue.image) {
        const {
          code: imgCode,
          msg: imgMsg,
          data: imgData,
        } = await createImageOnPost({ ...imgValue, post_id: saveData.id });
        if (!imgData) {
          console.log(imgCode, imgMsg);
        }
      }
      const { code, msg, data } = await approvePendingPost(
        {
          post_id: saveData.id,
        },
        token.access_token
      );
      if (code < 300) {
        updateListOfPost("create", data, []);
        navigate(`/nest/${data.community_id}/post/${data.id}`);
      } else {
        console.log(code, msg);
      }
    } else {
      console.log(saveCode, saveMsg);
    }
  };

  return (
    <div className={classNames(styles.container, pageStyle.pageBox)}>
      <h1 className={styles.title}>포스팅</h1>
      <form className={styles.form} onSubmit={handleUploadSubmit}>
        <div className={styles.formGroup}>
          <select
            className={styles.selectType}
            onChange={handleSelect}
            value={values.community_id}
          >
            {filteredCommunities.map((item, idx) => (
              <option key={idx} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="title" className={styles.label}>
            제목
          </label>
          <input
            type="text"
            name="title"
            className={styles.input}
            required
            placeholder="제목을 입력하세요"
            onChange={handleChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="content" className={styles.label}>
            내용
          </label>
          <textarea
            name="content"
            className={styles.textarea}
            required
            placeholder="내용을 입력하세요"
            rows="10"
            onChange={handleChange}
          ></textarea>
        </div>
        <div className={styles.formGroup}>
          <FileInput value={imgValue.image} onChange={handleImageChange} />
        </div>
        {/* <button type="submit" className={styles.button}>
          임시저장
        </button> */}
        <button type="submit" className={styles.button}>
          업로드
        </button>
      </form>
    </div>
  );
}

export default PostingPage;
