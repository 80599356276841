import { useAuth } from "../contexts/AuthProvider";
import { useNavigate } from "react-router-dom";
import nestock from "../assets/logo.png";
import Korea from "../assets/Korea.svg";
import USA from "../assets/USA.svg";
import userIcon from "../assets/person.svg";
import { ReactComponent as Plus } from "../assets/icon--plus.svg";
import styles from "./ShowPageProfile.module.css";

function ShowPageProfile({ option, data = null }) {
  const { user } = useAuth();
  const navigate = useNavigate();
  let profileIMG;
  let pageInfo;
  switch (option) {
    case "user":
      pageInfo = {
        id: data.id,
        title: data.username,
        created_at: data.created_at,
      };
      profileIMG = userIcon;
      break;
    case "mentor":
      break;
    case "community":
      const {
        id,
        name: title,
        description,
        created_by,
        created_at,
        updated_at,
      } = data;
      pageInfo = {
        id,
        title,
        description,
        created_by,
        created_at,
        updated_at,
      };
      switch (title) {
        case "국내주식":
          profileIMG = Korea;
          break;
        case "미국주식":
          profileIMG = USA;
          break;
        default:
          profileIMG = nestock;
          break;
      }
      break;
    case "manager":
      return (
        <>
          <div
            alt="프로필 배경 이미지"
            style={{
              backgroundColor: "#f0f0f0",
            }}
            className={styles.background}
          />
          <div className={styles.profileContainer}>
            <img src={nestock} alt="관리자" className={styles.pfImg} />
            <div className={styles.profile}>
              <p className={styles.title}>관리자</p>
            </div>
          </div>
        </>
      );
    default:
      return;
  }
  const isNotice = option === "community" && pageInfo.title === "공지";

  return (
    <>
      <div className={styles.profileContainer}>
        <img src={profileIMG} alt="프로필 이미지" className={styles.pfImg} />
        <div className={styles.pf}>
          <div className={styles.pfUpward}>
            <p className={styles.title}>{pageInfo.title}</p>
          </div>
          <div className={styles.profileBox}>
            <div className={styles.profile}>
              {/* <p className={styles.title}>{pageInfo.title}</p> */}
              {(() => {
                switch (option) {
                  case "user":
                    return (
                      <>
                        {/* <div className={styles.buttonContainer}>
                          <button className={styles.button}>
                            <h2>팔로우</h2>
                          </button>
                        </div> */}
                        {/* <div className={styles.createdAt}>
                            가입일: {pageInfo.created_at.split("T")[0]}
                          </div> */}
                      </>
                    );
                  case "mentor":
                    return (
                      <div className={styles.createdAt}>
                        가입일: {pageInfo.created_at.split("T")[0]}
                      </div>
                    );
                  case "community":
                    if (isNotice) {
                      return (
                        <>
                          <div className={styles.buttonContainer}>
                            {/* <button className={styles.button}>
                              <h2>팔로우</h2>
                            </button> */}
                            {user.role === "ADMIN" && (
                              <button
                                className={styles.button}
                                onClick={() =>
                                  navigate("/posting", {
                                    state: {
                                      community_id: pageInfo.id,
                                      community_title: pageInfo.title,
                                    },
                                  })
                                }
                              >
                                <Plus />
                                <h4 className={styles.posting}>포스팅</h4>
                              </button>
                            )}
                          </div>
                          <div className={styles.createdAt}>
                            생성일: {pageInfo.created_at.split("T")[0]}
                          </div>
                        </>
                      );
                    }
                    return (
                      <>
                        <div className={styles.buttonContainer}>
                          {/* <button className={styles.button}>
                            <h2>팔로우</h2>
                          </button> */}
                          <button
                            className={styles.button}
                            onClick={() =>
                              navigate("/posting", {
                                state: {
                                  community_id: pageInfo.id,
                                  community_title: pageInfo.title,
                                },
                              })
                            }
                          >
                            <Plus />
                            <h4 className={styles.posting}>포스팅</h4>
                          </button>
                        </div>
                        <div className={styles.createdAt}>
                          생성일: {pageInfo.created_at.split("T")[0]}
                        </div>
                      </>
                    );
                  default:
                    return <></>;
                }
              })()}
            </div>
            <div className={styles.follow}>
              <span>팔로워 </span>
              <span>1k</span>
            </div>
            <div className={styles.follow}>
              <span>팔로잉 </span>
              <span>30</span>
            </div>
          </div>
        </div>
      </div>
      {user.id === pageInfo.id && (
        <div className={styles.editProfile}>
          <button
            onClick={() => navigate(`/user/${user.id}/edit`)}
            className={styles.editButton}
          >
            프로필 편집
          </button>
        </div>
      )}
    </>
  );
}

export default ShowPageProfile;
