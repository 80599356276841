import { useNestock } from "../contexts/NestockProvider";
import { useAuth } from "../contexts/AuthProvider";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getMyPost } from "../api/PostApi";
import ShowPageProfile from "../components/ShowPageProfile";
import ReadingOptions from "../components/ReadingOptions";
import TabContent from "../components/TabContent";
import pageStyle from "../components/ui/PageBox.module.css";
import NotFoundPage from "./NotFoundPage";

function UserPage() {
  const { userList, communityList, refreshUserList } = useNestock();
  const { user, token: access_token } = useAuth();
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [isMoreLeft, setIsMoreLeft] = useState(true);
  const [activeTab, setActiveTab] = useState(
    user.role !== "EXPERT" ? "posts" : "infoShare"
  );
  const location = Number(useLocation().pathname.split("/").slice(-1)[0]);
  const target = location === user.id ? "me" : location;
  const targetUser =
    target === "me" ? user : userList.find(({ id }) => id === target);
  const handleLoadMore = async () => {
    if (target !== "me") {
      setIsMoreLeft(false);
      return;
    }
    if (!isMoreLeft) {
      alert("모든 게시물을 열람하셨습니다");
      return;
    }
    const { data } = await getMyPost(
      {
        page,
        page_size: 20,
      },
      access_token
    );
    if (!data) return;
    if (data.length === 0) {
      alert("모든 게시물을 열람하셨습니다");
    } else {
      if (0 < data.length < 20) {
        setIsMoreLeft(false);
      }
      setPage((page) => page + 1);
      const newPosts = data.map((post) => {
        const { user_id, community_id, ...rest } = post;
        return {
          ...rest,
          User: {
            user_id: user_id,
            username: user.username,
          },
          Community: {
            community_id: community_id,
            communityTitle: communityList.find(
              (commu) => commu.id === community_id
            ).name,
          },
        };
      });
      setPosts((prevItem) => [...prevItem, ...newPosts]);
    }
  };
  useEffect(() => {
    async function getMyPosts(isFirst) {
      if (isFirst) {
        await refreshUserList();
      }
      handleLoadMore();
    }
    getMyPosts(page === 1);
  }, []);
  if (userList.findIndex((user) => user.id === location) < 0)
    return <NotFoundPage title="존재하지 않는 유저입니다" />;
  return (
    <div className={pageStyle.pageBox}>
      <ShowPageProfile option={"user"} data={targetUser} />
      <ReadingOptions
        isMe={target === "me"}
        role={targetUser.role}
        activeTab={activeTab}
        handleClick={setActiveTab}
      />
      <TabContent
        posts={posts}
        handleLoadMore={handleLoadMore}
        activeTab={activeTab}
        // badges={user.badges}
      />
    </div>
  );
}

export default UserPage;
