// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowPost_preview__V\\+B-M {
  overflow: hidden;
  text-overflow: ell;
}

.ShowPost_postContainer__ss9dk {
  padding-top: 40px;
  padding-bottom: 30px;
}

.ShowPost_post__K413X {
  white-space: pre-wrap;
  line-height: 1.5;
}

.ShowPost_post__K413X.ShowPost_preview__V\\+B-M {
  display: -webkit-box;
  overflow: hidden;
  word-break: keep-all;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.ShowPost_texts__2ZJo0 > h4 {
  float: right;
}

.ShowPost_imgListBox__Npl2L {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ShowPost_img__qe-Ic {
  object-fit: contain;
  width: -moz-fit-content;
  width: fit-content;
  max-height: 300px;
}

@media screen and (max-width: 960px) {
  .ShowPost_img__qe-Ic {
    max-width: calc(94vw - 40px);
  }
}

@media screen and (min-width: 960px) and (max-width: 1200px) {
  .ShowPost_img__qe-Ic {
    max-width: calc(55vw - 40px);
  }
}

@media screen and (min-width: 1200px) {
  .ShowPost_img__qe-Ic {
    max-width: calc(50vw - 40px);
  }
}

.ShowPost_viewCount__bx5fm {
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/components/ShowPost.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,oBAAoB;EACpB,qBAAqB;EACrB,4BAA4B;AAC9B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,uBAAkB;EAAlB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".preview {\n  overflow: hidden;\n  text-overflow: ell;\n}\n\n.postContainer {\n  padding-top: 40px;\n  padding-bottom: 30px;\n}\n\n.post {\n  white-space: pre-wrap;\n  line-height: 1.5;\n}\n\n.post.preview {\n  display: -webkit-box;\n  overflow: hidden;\n  word-break: keep-all;\n  -webkit-line-clamp: 5;\n  -webkit-box-orient: vertical;\n}\n\n.texts > h4 {\n  float: right;\n}\n\n.imgListBox {\n  margin-bottom: 15px;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.img {\n  object-fit: contain;\n  width: fit-content;\n  max-height: 300px;\n}\n\n@media screen and (max-width: 960px) {\n  .img {\n    max-width: calc(94vw - 40px);\n  }\n}\n\n@media screen and (min-width: 960px) and (max-width: 1200px) {\n  .img {\n    max-width: calc(55vw - 40px);\n  }\n}\n\n@media screen and (min-width: 1200px) {\n  .img {\n    max-width: calc(50vw - 40px);\n  }\n}\n\n.viewCount {\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preview": `ShowPost_preview__V+B-M`,
	"postContainer": `ShowPost_postContainer__ss9dk`,
	"post": `ShowPost_post__K413X`,
	"texts": `ShowPost_texts__2ZJo0`,
	"imgListBox": `ShowPost_imgListBox__Npl2L`,
	"img": `ShowPost_img__qe-Ic`,
	"viewCount": `ShowPost_viewCount__bx5fm`
};
export default ___CSS_LOADER_EXPORT___;
