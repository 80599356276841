import { useAuth } from "../contexts/AuthProvider";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DateText from "./DateText";
import pf from "../assets/logo.png";
import person from "../assets/person.svg";
import Korea from "../assets/Korea.svg";
import USA from "../assets/USA.svg";
import { ReactComponent as EtcIcon } from "../assets/icon--etc.svg";
import styles from "./ShowPostProfile.module.css";
import classNames from "classnames";

function ShowPostProfile({
  isOnList = false,
  isComment = false,
  showOnlyUser,
  profile,
  typeAndIds,
  infoToUpdate,
  time,
  setEdittingID = () => {},
  onDeletePost = () => {},
  onDeleteComment = () => {},
}) {
  const { isOnline, user, token: access_token } = useAuth();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const {
    type,
    ids: { post_id, comment_id },
  } = typeAndIds;
  let pfImg;
  if (type === "posts") {
    if (profile.Community.communityTitle === "국내주식") {
      pfImg = Korea;
    } else if (profile.Community.communityTitle === "미국주식") {
      pfImg = USA;
    } else {
      pfImg = pf;
    }
  }
  const handleRevise = () => {
    switch (type) {
      case "posts":
        navigate(`/posting/${post_id}`, {
          state: {
            user_id: profile.User.user_id,
            title: infoToUpdate.title,
            content: infoToUpdate.content,
            comments: infoToUpdate.comments,
          },
        });
        break;
      case "comments":
        setEdittingID(comment_id);
        break;
      default:
        break;
    }
  };
  const handleDelete = async () => {
    const confirmed = window.confirm(
      `해당 ${type === "posts" ? "게시물" : "댓글"}을 지우시겠습니까?`
    );
    if (!confirmed) return;
    switch (type) {
      case "posts":
        onDeletePost(post_id, access_token);
        return;
      case "comments":
        onDeleteComment(comment_id);
        // setPostList((prevList) => {
        //   const splitIdx = prevList.findIndex((post) => post.id === post_id);
        //   const post = prevList[splitIdx];
        //   const prevComments = post.comments;
        //   const nextComments = prevComments.filter(
        //     (comment) => comment.id !== comment_id
        //   );
        //   post.comments = nextComments;
        //   return [
        //     ...prevList.splice(0, splitIdx),
        //     post,
        //     ...prevList.splice(splitIdx + 1),
        //   ];
        // });
        return;
      default:
        console.log("type 오류:", type);
        return;
    }
  };
  return (
    <div
      className={classNames(
        styles.profileContainer,
        isOnList && styles.isOnList,
        isComment && styles.comment
      )}
    >
      {showOnlyUser ? (
        <div
          className={classNames(styles.profile, isComment && styles.comment)}
        >
          <img
            src={person}
            className={classNames(styles.userIMG, isComment && styles.comment)}
            alt="유저 이미지"
          />
          <Link to={`/user/${profile.User.user_id}`}>
            <h4 className={styles.avatar}>{profile.User.username}</h4>
          </Link>
          <DateText value={time} />
        </div>
      ) : (
        <div className={classNames(styles.profile, styles.optional)}>
          <img
            src={pfImg}
            className={styles.communityIMG}
            alt="커뮤니티 이미지"
          />
          <Link to={`/nest/${profile.Community.community_id}`}>
            <h4 className={styles.avatar}>
              {profile.Community.communityTitle}
            </h4>
          </Link>
          <Link to={`/user/${profile.User.user_id}`}>
            <h5 className={styles.avatar}>{profile.User.username}</h5>
          </Link>
          <DateText value={time} />
        </div>
      )}
      {isOnline && (
        <div
          className={styles.etc}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
        >
          <div className={styles.etcIMG}>
            <EtcIcon />
          </div>
          {isOpen &&
            (() => {
              if (user.id === profile.User.user_id) {
                return (
                  <div className={styles.etcButtonBox}>
                    <button className={styles.button} onClick={handleRevise}>
                      <h4>수정</h4>
                    </button>
                    <button className={styles.button} onClick={handleDelete}>
                      <h4>삭제</h4>
                    </button>
                  </div>
                );
              } else if (
                user.id !== profile.User.user_id &&
                user.role === "ADMIN"
              ) {
                return (
                  <div className={styles.etcButtonBox}>
                    <button className={styles.button} onClick={handleDelete}>
                      <h4>삭제</h4>
                    </button>
                  </div>
                );
              } else {
                setIsOpen(false);
                return;
              }
            })()}
        </div>
      )}
    </div>
  );
}

export default ShowPostProfile;
