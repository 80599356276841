import { useAuth } from "../contexts/AuthProvider";
import { useState } from "react";
import { ReactComponent as LikeIcon } from "../assets/icon--like.svg";
import { ReactComponent as DislikeIcon } from "../assets/icon--dislike.svg";
import styles from "./RecommendCount.module.css";

function RecommendCount({ aboutLike }) {
  const { isOnline, user, handleLikeAndDislike } = useAuth();
  // const { likes, myLike } = aboutLike;
  const {
    type,
    id,
    likes: { count, setCount },
  } = aboutLike;
  const theElem = user[type].find((elem) => elem.id === id);
  const [option, setOption] = useState(theElem ? theElem.like_type : "none");
  const notColoredStyle = {
    color: "transparent",
  };

  const handleLikeClick = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!isOnline) {
      alert("추천/비추천 기능은 로그인하셔야 이용하실 수 있습니다");
      return;
    }
    if (!theElem) {
      const origin = await handleLikeAndDislike({
        id,
        type,
        like_type: "like",
      });
      switch (origin) {
        case "like":
          console.log("here1");
          break;
        case "dislike":
          setCount(2);
          console.log("here2");
          break;
        case "none":
          setCount(1);
          console.log("here3");
          break;
        default:
          console.log("error1");
          break;
      }
      setOption("like");
      return;
    } else {
      switch (option) {
        case "like":
          setCount(-1);
          setOption("none");
          handleLikeAndDislike({
            id,
            type,
            like_type: "like",
            current_type: "none",
          });
          console.log("here4");
          return;
        case "dislike":
          setCount(2);
          setOption("like");
          handleLikeAndDislike({
            id,
            type,
            like_type: "like",
            current_type: "like",
          });
          console.log("here5");
          return;
        case "none":
          setCount(1);
          setOption("like");
          handleLikeAndDislike({
            id,
            type,
            like_type: "like",
            current_type: "like",
          });
          console.log("here6");
          return;
        default:
          console.log("error2");
          return;
      }
    }
  };

  const handleDislikeClick = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!isOnline) {
      alert("추천/비추천 기능은 로그인하셔야 이용하실 수 있습니다");
      return;
    }
    if (!theElem) {
      const origin = await handleLikeAndDislike({
        id,
        type,
        like_type: "dislike",
      });
      switch (origin) {
        case "like":
          setCount(-2);
          console.log("here7");
          break;
        case "dislike":
          console.log("here8");
          break;
        case "none":
          setCount(-1);
          console.log("here9");
          break;
        default:
          console.log("error3");
          break;
      }
      setOption("dislike");
      return;
    } else {
      switch (option) {
        case "like":
          setCount(-2);
          setOption("dislike");
          handleLikeAndDislike({
            id,
            type,
            like_type: "dislike",
            current_type: "dislike",
          });
          console.log("here10");
          return;
        case "dislike":
          setCount(1);
          setOption("none");
          handleLikeAndDislike({
            id,
            type,
            like_type: "dislike",
            current_type: "none",
          });
          console.log("here11");
          return;
        case "none":
          setCount(-1);
          setOption("dislike");
          handleLikeAndDislike({
            id,
            type,
            like_type: "dislike",
            current_type: "dislike",
          });
          console.log("here12");
          return;
        default:
          console.log("error4");
          return;
      }
    }
  };
  return (
    <div className={styles.recommended}>
      <div name="like" className={styles.imgBox} onClick={handleLikeClick}>
        {option === "like" ? (
          <LikeIcon fill="#FF6464" style={notColoredStyle} />
        ) : (
          <LikeIcon fill="none" />
        )}
      </div>
      <p>{count}</p>
      <div
        name="dislike"
        className={styles.imgBox}
        onClick={handleDislikeClick}
      >
        {option === "dislike" ? (
          <DislikeIcon fill="#6464FF" style={notColoredStyle} />
        ) : (
          <DislikeIcon fill="none" />
        )}
      </div>
    </div>
  );
}

export default RecommendCount;
