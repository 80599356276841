// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InteractionMenu_menu__A1o-s {
  height: 30px;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.InteractionMenu_menu__A1o-s.InteractionMenu_isOnList__EMCGv {
  bottom: 5px;
}

.InteractionMenu_menu__A1o-s.InteractionMenu_comment__Sorxt {
  bottom: 0;
  left: 35px;
}

.InteractionMenu_menu__A1o-s > * {
  width: -moz-fit-content;
  width: fit-content;
  height: 30px;
  border-radius: 15px;
  float: left;
}

.InteractionMenu_menu__A1o-s:not(.InteractionMenu_comment__Sorxt) > * {
  background-color: rgb(220, 220, 220);
}

@media screen and (max-width: 960px) {
  .InteractionMenu_menu__A1o-s > *:not(:first-child):active {
    background-color: rgb(200, 200, 200);
  }
}

@media screen and (min-width: 960px) {
  .InteractionMenu_menu__A1o-s > *:not(:first-child):hover {
    background-color: rgb(200, 200, 200);
  }
}

.InteractionMenu_menu__A1o-s > *:not(.InteractionMenu_share__wT8Xu) {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.InteractionMenu_commented__aDCLh > p {
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 10px;
  margin: 0;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/InteractionMenu.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE;IACE,oCAAoC;EACtC;AACF;;AAEA;EACE;IACE,oCAAoC;EACtC;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,mBAAmB;EACnB,SAAS;EACT,kBAAkB;AACpB","sourcesContent":[".menu {\n  height: 30px;\n  position: absolute;\n  bottom: 10px;\n  left: 10px;\n}\n\n.menu.isOnList {\n  bottom: 5px;\n}\n\n.menu.comment {\n  bottom: 0;\n  left: 35px;\n}\n\n.menu > * {\n  width: fit-content;\n  height: 30px;\n  border-radius: 15px;\n  float: left;\n}\n\n.menu:not(.comment) > * {\n  background-color: rgb(220, 220, 220);\n}\n\n@media screen and (max-width: 960px) {\n  .menu > *:not(:first-child):active {\n    background-color: rgb(200, 200, 200);\n  }\n}\n\n@media screen and (min-width: 960px) {\n  .menu > *:not(:first-child):hover {\n    background-color: rgb(200, 200, 200);\n  }\n}\n\n.menu > *:not(.share) {\n  display: flex;\n  align-items: center;\n  margin-right: 15px;\n}\n\n.commented > p {\n  width: fit-content;\n  padding-right: 10px;\n  margin: 0;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `InteractionMenu_menu__A1o-s`,
	"isOnList": `InteractionMenu_isOnList__EMCGv`,
	"comment": `InteractionMenu_comment__Sorxt`,
	"share": `InteractionMenu_share__wT8Xu`,
	"commented": `InteractionMenu_commented__aDCLh`
};
export default ___CSS_LOADER_EXPORT___;
