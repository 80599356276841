import { useNestock } from "../contexts/NestockProvider";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import PostList from "../components/PostList";
import NotFoundPage from "./NotFoundPage";
import ShowPageProfile from "../components/ShowPageProfile";
import pageStyle from "../components/ui/PageBox.module.css";
import { getListOfPost } from "../api/PostApi";

function CommunityPage() {
  const { userList, communityList } = useNestock();
  const location = useLocation().pathname.split("/");
  const [page, setPage] = useState(1);
  const [isMoreLeft, setIsMoreLeft] = useState(true);
  const [community, setCommunity] = useState(() => {
    const communityID = Number(location[2]);
    return communityList.find(({ id }) => id === communityID);
  });

  const handleLoadMore = async () => {
    if (!isMoreLeft) {
      alert("모든 게시물을 열람하셨습니다");
      return;
    }
    //  if (page === 1 && community.posts) return; // 만약 setComunityList를 통해 community.posts를 저장하게 된다면
    const { data } = await getListOfPost({
      community_id: community.id,
      page,
      page_size: 20,
    });
    if (data.length === 0) {
      alert("모든 게시물을 열람하셨습니다");
    } else {
      if (0 < data.length < 20) {
        setIsMoreLeft(false);
      }
      setPage((page) => page + 1);
      const newPosts = data.map((post) => {
        const {
          User: { username },
          ...rest
        } = post;
        return {
          ...rest,
          User: {
            user_id: userList.find((user) => user.username === username).id,
            username,
          },
          Community: {
            community_id: community.id,
            communityTitle: community.name,
          },
        };
      });
      setCommunity((prevItem) => {
        const posts = prevItem.posts
          ? [...prevItem.posts, ...newPosts]
          : newPosts;
        return {
          ...prevItem,
          posts,
        };
      });
    }
  };

  function addVisitHistory(log) {
    if (!log) return;
    const historyData = localStorage.getItem("visitHistory");
    const prevHistory = historyData ? JSON.parse(historyData) : [];
    const filtered = prevHistory.filter(({ id }) => id !== log.id);
    const addedHistory = [log, ...filtered].splice(0, 20);
    localStorage.setItem("visitHistory", JSON.stringify(addedHistory));
  }

  useEffect(() => {
    addVisitHistory({ id: community.id, name: community.name });
  }, [location]);

  useEffect(() => {
    handleLoadMore();
  }, []);

  if (!community) {
    return (
      <NotFoundPage
        variant="big"
        title="존재하지 않는 커뮤니티입니다"
        description="올바른 주소가 맞는지 다시 한 번 확인해 주세요"
      />
    );
  }

  return (
    <div className={pageStyle.pageBox}>
      <ShowPageProfile option={"community"} data={community} />
      <PostList
        showCommunity={false}
        posts={community.posts}
        handleLoadMore={handleLoadMore}
      />
    </div>
  );
}

export default CommunityPage;
