import { useNestock } from "../contexts/NestockProvider";
import { useAuth } from "../contexts/AuthProvider";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getPost,
  getListOfImageOnPost,
  getListOfCommentOnPost,
  createComment,
  updateComment,
  deleteComment,
} from "../api/PostApi";
import ShowPost from "../components/ShowPost";
import ShowPostProfile from "../components/ShowPostProfile";
import InteractionMenu from "../components/InteractionMenu";
import { ReactComponent as CommmentIcon } from "../assets/icon--comment.svg";
import arrow from "../assets/icon--arrow-to-left.svg";
import person from "../assets/person.svg";
import styles from "./PostPage.module.css";
import pageStyle from "../components/ui/PageBox.module.css";
import classNames from "classnames";

function ShowComment({ info }) {
  const {
    id,
    user_id,
    username,
    post,
    parent_id,
    // is_anonymous,
    content,
    likes,
    created_at,
    updated_at,
    setPost,
    setEdittingID,
    handleDeleteComment,
    updateListOfPost,
  } = info;
  const [likesCount, setLikesCount] = useState(likes);
  const handleLikesCount = (likesChange) => {
    const splitIdx = post.comments.findIndex((comment) => comment.id === id);
    setPost((prevPost) => ({
      ...prevPost,
      comments: [
        ...post.comments.slice(0, splitIdx),
        {
          ...post.comments[splitIdx],
          likes: Number(likes) + likesChange,
        },
        ...post.comments.slice(splitIdx + 1),
      ],
    }));
    setLikesCount((count) => {
      let num = Number(count) + likesChange;
      return num.toString();
    });
    updateListOfPost("update", post, [
      ...post.comments.slice(0, splitIdx),
      {
        ...post.comments[splitIdx],
        likes: Number(likes) + likesChange,
      },
      ...post.comments.slice(splitIdx + 1),
    ]);
  };

  // window.addEventListener("unload", () => {
  // 페이지가 언로드될 때 실행할 코드 작성 (추천/비추천 post 보내기)
  // });
  return (
    <div className={styles.commentBox}>
      <ShowPostProfile
        isComment={true}
        showOnlyUser={true}
        profile={{ User: { user_id, username }, parent_id }}
        typeAndIds={{
          type: "comments",
          ids: { post_id: post.id, comment_id: id },
        }}
        time={{ created_at, updated_at }}
        setEdittingID={setEdittingID}
        onDeleteComment={handleDeleteComment}
      />
      <div className={styles.commentContainer}>
        <p>{content}</p>
        <InteractionMenu
          type="comments"
          likes={{ count: likesCount, setCount: handleLikesCount }}
          id={id}
        />
      </div>
    </div>
  );
}

function EditComment({ info, onCancel, onSubmit }) {
  const {
    id,
    user_id,
    username,
    post_id,
    parent_id,
    // is_anonymous,
    content,
    created_at,
    updated_at,
    setEdittingComment,
  } = info;
  const [editted, setEditted] = useState(content);
  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setEditted(value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ comment_id: id, content: editted });
  };

  // window.addEventListener("unload", () => {
  // 페이지가 언로드될 때 실행할 코드 작성 (추천/비추천 post 보내기)
  // });
  return (
    <div className={styles.commentBox}>
      <ShowPostProfile
        isComment={true}
        showOnlyUser={true}
        profile={{ User: { user_id, username }, parent_id }}
        typeAndIds={{ type: "comments", ids: { post_id, comment_id: id } }}
        time={{ created_at, updated_at }}
        setEdittingComment={setEdittingComment}
      />
      <div className={styles.commentContainer}>
        <input
          className={styles.editInput}
          onChange={handleChange}
          value={editted}
        />
      </div>
      <div className={styles.buttonBox}>
        <button className={styles.editButton} onClick={handleSubmit}>
          <h3>완료</h3>
        </button>
        <button className={styles.editButton} onClick={onCancel}>
          <h3>취소</h3>
        </button>
      </div>
    </div>
  );
}

function PostPage() {
  const { userList, communityList, updateListOfPost, postList, setPostList } =
    useNestock();
  const {
    user,
    token: { access_token },
  } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const post_id = Number(location.pathname.split("/")[4]);
  const [post, setPost] = useState();
  const [edittingID, setEdittingID] = useState(null);
  const INITIAL_VALUES = {
    post_id,
    content: "",
    // parent_id: "",
  };
  const [myComment, setMyComment] = useState(INITIAL_VALUES);
  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setMyComment((prevComment) => ({ ...prevComment, content: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user.id === 0) {
      alert("로그인 후 이용해주세요");
      return;
    }
    const { code, msg, data } = await createComment(myComment, access_token);
    if (code < 300) {
      const newComments = [
        ...post.comments,
        {
          ...data,
          username: userList.find((user) => user.id === data.user_id).username,
          likes: 0,
        },
      ];
      updateListOfPost("update", post, newComments);
      setPost((prevPost) => ({ ...prevPost, comments: newComments }));
      setMyComment((prevComment) => ({ ...prevComment, ...INITIAL_VALUES }));
    } else {
      alert(code, msg);
    }
  };
  const handleDeleteComment = async (comment_id) => {
    const { code, msg } = await deleteComment({ comment_id }, access_token);
    if (code < 300) {
      const prevComments = post.comments;
      setPost((prevPost) => ({
        ...prevPost,
        comments: prevComments.filter((comment) => comment.id !== comment_id),
      }));
      updateListOfPost(
        "update",
        post,
        prevComments.filter((comment) => comment.id !== comment_id)
      );
    } else {
      alert(msg);
    }
  };
  const handleCancel = (e) => {
    e.preventDefault();
    setEdittingID(null);
  };
  const handleEditSubmit = async (comment) => {
    const { code, msg, data } = await updateComment(comment, access_token);
    if (data) {
      const replaceIdx = post.comments.findIndex(
        (comment) => comment.id === data.id
      );
      const newComments = [
        ...post.comments.slice(0, replaceIdx),
        {
          ...data,
          username: userList.find((user) => user.id === data.user_id).username,
          likes: post.comments[replaceIdx].likes,
        },
        ...post.comments.slice(replaceIdx + 1),
      ];
      updateListOfPost("update", post, newComments);
      setPost((prevPost) => ({ ...prevPost, comments: newComments }));
    } else {
      alert(code, msg);
    }
    setEdittingID(null);
  };
  const handlePostLikes = (likesChange) =>
    setPost((prevPost) => ({
      ...prevPost,
      likes: Number(prevPost.likes) + likesChange,
    }));

  useEffect(() => {
    async function GettingPost() {
      const thatPost = postList.find(({ id }) => id === post_id);
      if (thatPost) {
        setPost(thatPost);
        return;
      }
      const { data: postData } = await getPost({ post_id });
      if (postData) {
        let images = [];
        const { code, msg, data } = await getListOfImageOnPost(
          { post_id },
          access_token
        );
        if (!data) console.log(code, msg);
        else images = [...data];
        let comments = [];
        let page = 1;
        let isMoreLeft = true;
        do {
          const { data: commentData } = await getListOfCommentOnPost({
            post_id,
            page,
            page_size: 20,
          });
          if (commentData.length < 20) isMoreLeft = false;
          const newComments = commentData
            .map((comment) => {
              const { user_id, ...rest } = comment;
              return {
                user_id,
                username: userList.find((user) => user.id === user_id).username,
                ...rest,
              };
            })
            .reverse();
          comments = [...comments, ...newComments];
        } while (isMoreLeft);
        const {
          status,
          community_id,
          user_id,
          view_count,
          updated_at,
          User: { username },
          ...restProps
        } = postData;
        const commuName = communityList.find(
          (commu) => commu.id === community_id
        ).name;
        const newPost = {
          ...restProps,
          User: { user_id, username },
          Community: { community_id, communityTitle: commuName },
          comments,
          images,
          status,
          view_count,
          updated_at,
        };
        setPostList((prevList) => [...prevList, newPost]);
        setPost(newPost);
      }
    }
    GettingPost();
  }, []);
  if (!post) return <div>Loading...</div>;
  return (
    <div className={classNames(pageStyle.pageBox, styles.pageBox)}>
      <div className={styles.communityBox}>
        <div className={styles.imgBox} onClick={() => navigate(-1)}>
          <img src={arrow} alt="돌아가기" />
        </div>
        <Link to={`/nest/${post.Community.community_id}`}>
          <h4 className={styles.community}>
            {post.Community.communityTitle} 커뮤니티
          </h4>
        </Link>
      </div>
      <div className={styles.postBox}>
        <ShowPost
          value={post}
          imgList={post?.images}
          isPage={true}
          handleLikes={handlePostLikes}
        />
      </div>
      <div className={styles.commentListBox}>
        <h2>댓글 {post.comments.length}개</h2>
        {post.comments.length > 0 &&
          post.comments.map((comment) => {
            if (comment.id === edittingID) {
              return (
                <EditComment
                  key={comment.id}
                  info={comment}
                  onCancel={handleCancel}
                  onSubmit={handleEditSubmit}
                />
              );
            } else {
              return (
                <ShowComment
                  key={comment.id}
                  info={{
                    ...comment,
                    post,
                    setPost,
                    setEdittingID,
                    handleDeleteComment,
                    updateListOfPost,
                  }}
                />
              );
            }
          })}
      </div>
      <div className={styles.commentInputBox}>
        <form className={styles.commentForm} onSubmit={handleSubmit}>
          <label className={styles.commentLabel} htmlFor="comment">
            <img className={styles.img} src={person} alt="이미지" />
          </label>
          <input
            className={styles.commentInput}
            type="text"
            name="content"
            placeholder="댓글 입력..."
            autoComplete="off"
            required
            onChange={handleChange}
            value={myComment.content}
          />
          <button type="submit" className={styles.commentSubmit}>
            <CommmentIcon fill="#fff" />
          </button>
        </form>
      </div>
    </div>
  );
}

export default PostPage;
