import { useState } from "react";
import { useNavigate } from "react-router-dom";
import user from "../assets/person.svg";
import { ReactComponent as Plus } from "../assets/icon--plus.svg";
import { ReactComponent as Back } from "../assets/icon--arrow-to-left.svg";
import pageStyle from "../components/ui/PageBox.module.css";
import styles from "./ProfileEditPage.module.css";

function ProfileEditPage() {
  const [displayName, setDisplayName] = useState("Jaysontatum");
  const [about, setAbout] = useState("");
  const navigate = useNavigate();
  const maxLength = 100;

  return (
    <div className={pageStyle.pageBox}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <button onClick={() => navigate(-1)} className={styles.backButton}>
              <Back />
            </button>
            <h1 className={styles.pageTitle}>프로필 편집</h1>
          </div>
          <button onClick={() => alert("저장")} className={styles.saveButton}>
            저장
          </button>
        </div>
      </div>
      <div className={styles.mainContent}>
        <div className={styles.avatarSection}>
          <div className={styles.avatarWrapper}>
            <div className={styles.largeAvatar}>
              <img src={user} alt="프로필" className={styles.avatarImage} />
            </div>
            <button className={styles.uploadButton}>
              <Plus />
            </button>
          </div>
        </div>

        <div className={styles.formSection}>
          <div className={styles.formGroup}>
            <label className={styles.label}>닉네임</label>
            <input
              type="text"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              className={styles.input}
              placeholder="닉네임을 입력하세요"
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>소개글</label>
            <textarea
              value={about}
              onChange={(e) => {
                if (e.target.value.length <= maxLength) {
                  setAbout(e.target.value);
                }
              }}
              className={styles.textarea}
              placeholder="자기소개를 입력하세요 (최대 100자)"
            />
            <div className={styles.charCount}>
              {about.length}/{maxLength}자
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileEditPage;
