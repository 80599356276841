import { useNestock } from "../contexts/NestockProvider";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { deletePost } from "../api/PostApi";
import ShowPostProfile from "./ShowPostProfile";
import InteractionMenu from "./InteractionMenu";
import styles from "./ShowPost.module.css";
import classNames from "classnames";

function ShowPost({
  isOnList = false,
  value,
  imgList,
  showOnlyUser = true,
  isPage = false,
  handleLikes = () => {},
}) {
  const { updateListOfPost } = useNestock();
  // const [links, setLinks] = useState;
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const {
    id,
    title,
    content,
    likes,
    created_at,
    User: { user_id, username },
    Community: { community_id, communityTitle },
    comments,
    ///////////////
    status,
    view_count,
    updated_at,
  } = value;
  const [likesCount, setLikesCount] = useState(likes);
  const profile = {
    User: { user_id, username },
    Community: { community_id, communityTitle },
  };

  const HyperlinkText = ({ text }) => {
    // URL을 감지하는 정규식
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // 텍스트를 URL 기준으로 분리하고 하이퍼링크로 변환
    const parsedText = text.split(urlRegex).map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#ff6f00" }}
          >
            {part}
          </a>
        );
      }
      return part;
    });

    return (
      <p className={classNames(styles.post, !isPage && styles.preview)}>
        {parsedText}
      </p>
    );
  };

  const handleClick = (e) => {
    e.stopPropagation();
    if (isPage) return;
    navigate(`/nest/${community_id}/post/${id}`);
  };

  const handleDeletePost = async (post_id, access_token) => {
    const { code, msg } = await deletePost({ post_id }, access_token);
    if (code < 300) {
      updateListOfPost("delete", value, value.comments);
      if (location.split("/")[3] === "post") {
        navigate(-1);
      }
    } else {
      alert(msg);
    }
  };

  const handleLikesCount = (likesChange) => {
    handleLikes(likesChange);
    setLikesCount((prevCount) => Number(prevCount) + likesChange);
    updateListOfPost(
      "update",
      { ...value, likes: Number(value.likes) + likesChange },
      comments
    );
  };

  return (
    <>
      <ShowPostProfile
        isOnList={isOnList}
        showOnlyUser={showOnlyUser}
        profile={profile}
        typeAndIds={{ type: "posts", ids: { post_id: id } }}
        infoToUpdate={{ title, content, comments }}
        time={{ created_at, updated_at }}
        onDeletePost={handleDeletePost}
      />
      <div className={styles.postContainer} onClick={handleClick}>
        <div className={styles.texts}>
          <h2>{title}</h2>
          <HyperlinkText text={content} />
          <div className={styles.imgListBox}>
            {imgList &&
              imgList.map(({ id, image_url }) => (
                <img
                  key={id}
                  className={styles.img}
                  src={image_url}
                  alt="이미지"
                />
              ))}
          </div>
          {isPage && <h4 className={styles.viewCount}>조회 {view_count}</h4>}
        </div>
      </div>
      <InteractionMenu
        isOnList={isOnList}
        likes={{ count: likesCount, setCount: handleLikesCount }}
        comments={comments?.length}
        id={id}
      />
    </>
  );
}

export default ShowPost;
