// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PolicyPage_page_title__IhbBl {
  margin: 40px 0;
  font-size: 24px;
  font-weight: bold;
}

.PolicyPage_policy_content__5Rpeo {
  height: 300px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 15px;
  margin: 15px 0;
  background: #f9f9f9;
}

.PolicyPage_title__r2-na,
.PolicyPage_subtitle__doiyC {
  margin: 20px 0;
}

.PolicyPage_subtitle__doiyC:first-of-type {
  margin: 0;
}

.PolicyPage_article__56Alo {
  margin: 0;
  margin-bottom: 5px;
}

.PolicyPage_article__56Alo:not(:first-of-type) {
  margin-top: 15px;
}

.PolicyPage_subarticle__CQa9H {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/PolicyPage.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,sBAAsB;EACtB,aAAa;EACb,cAAc;EACd,mBAAmB;AACrB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,SAAS;AACX","sourcesContent":[".page_title {\n  margin: 40px 0;\n  font-size: 24px;\n  font-weight: bold;\n}\n\n.policy_content {\n  height: 300px;\n  overflow-y: auto;\n  border: 1px solid #ddd;\n  padding: 15px;\n  margin: 15px 0;\n  background: #f9f9f9;\n}\n\n.title,\n.subtitle {\n  margin: 20px 0;\n}\n\n.subtitle:first-of-type {\n  margin: 0;\n}\n\n.article {\n  margin: 0;\n  margin-bottom: 5px;\n}\n\n.article:not(:first-of-type) {\n  margin-top: 15px;\n}\n\n.subarticle {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page_title": `PolicyPage_page_title__IhbBl`,
	"policy_content": `PolicyPage_policy_content__5Rpeo`,
	"title": `PolicyPage_title__r2-na`,
	"subtitle": `PolicyPage_subtitle__doiyC`,
	"article": `PolicyPage_article__56Alo`,
	"subarticle": `PolicyPage_subarticle__CQa9H`
};
export default ___CSS_LOADER_EXPORT___;
