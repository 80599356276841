// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReadingOptions_tabContainer__XHC\\+P {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ReadingOptions_tabButton__6aq\\+p {
  height: 50px;
  padding: 5px;
  background-color: transparent;
  font-size: 16px;
  text-align: center;
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ReadingOptions_inactiveTab__Ipa35 > p {
  color: rgb(100, 100, 100);
}

.ReadingOptions_activeTab__Uo\\+pa > p {
  color: var(--nestock-color);
}

.ReadingOptions_highlight__DGuNf {
  width: 80%;
  height: 2px;
  background-color: transparent;
  position: absolute;
  bottom: 0;
  left: 10%;
}

.ReadingOptions_highlight__DGuNf.ReadingOptions_clicked__GS8WV {
  background-color: var(--nestock-color);
}

.ReadingOptions_inactiveTab__Ipa35:hover .ReadingOptions_highlight__DGuNf.ReadingOptions_hovered__aC2dO {
  background-color: rgb(200, 200, 200);
}

.ReadingOptions_manageMode__P1Xm4 {
  width: 40%;
  max-width: 200px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ReadingOptions.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,6BAA6B;EAC7B,eAAe;EACf,kBAAkB;EAClB,SAAO;EACP,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,UAAU;EACV,WAAW;EACX,6BAA6B;EAC7B,kBAAkB;EAClB,SAAS;EACT,SAAS;AACX;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB","sourcesContent":[".tabContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n}\n\n.tabButton {\n  height: 50px;\n  padding: 5px;\n  background-color: transparent;\n  font-size: 16px;\n  text-align: center;\n  flex: 1;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n}\n\n.inactiveTab > p {\n  color: rgb(100, 100, 100);\n}\n\n.activeTab > p {\n  color: var(--nestock-color);\n}\n\n.highlight {\n  width: 80%;\n  height: 2px;\n  background-color: transparent;\n  position: absolute;\n  bottom: 0;\n  left: 10%;\n}\n\n.highlight.clicked {\n  background-color: var(--nestock-color);\n}\n\n.inactiveTab:hover .highlight.hovered {\n  background-color: rgb(200, 200, 200);\n}\n\n.manageMode {\n  width: 40%;\n  max-width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContainer": `ReadingOptions_tabContainer__XHC+P`,
	"tabButton": `ReadingOptions_tabButton__6aq+p`,
	"inactiveTab": `ReadingOptions_inactiveTab__Ipa35`,
	"activeTab": `ReadingOptions_activeTab__Uo+pa`,
	"highlight": `ReadingOptions_highlight__DGuNf`,
	"clicked": `ReadingOptions_clicked__GS8WV`,
	"hovered": `ReadingOptions_hovered__aC2dO`,
	"manageMode": `ReadingOptions_manageMode__P1Xm4`
};
export default ___CSS_LOADER_EXPORT___;
