import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateUserRole } from "../api/UserApi";
import { approvePendingCommunity, deleteCommunity } from "../api/CommunityApi";
import CommunityList from "./CommunityList";
import person from "../assets/person.png";
import { ReactComponent as Search } from "../assets/search.svg";
import styles from "./TabContent.module.css";
import listStyles from "./CommunityList.module.css";
import classNames from "classnames";

function ManageMode({
  data = { nests: [], users: [] },
  activeTab,
  token = "",
  // badges
}) {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const { nests, users } = data;
  const [filteredList, setFilteredList] = useState(nests);

  const handleKeywordChange = (e) => {
    const newKeyword = e.target.value;
    setKeyword(newKeyword);
    if (!newKeyword) setFilteredList(data[activeTab]);
    const lowered = newKeyword.toLowerCase();
    switch (activeTab) {
      case "nests":
        setFilteredList(
          nests.filter(({ name }) => name.toLowerCase().includes(lowered))
        );
        break;
      case "users":
        setFilteredList(
          users.filter(({ username }) =>
            username.toLowerCase().includes(lowered)
          )
        );
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    setFilteredList(data[activeTab]);
  }, [activeTab]);

  return (
    <>
      <div className={styles.searchBox}>
        <div className={styles.imgBox}>
          <Search />
        </div>
        <input
          id="searchInput"
          name="keyword"
          value={keyword}
          onChange={handleKeywordChange}
          autoComplete="off"
          className={styles.searchInput}
        />
      </div>
      {(() => {
        switch (activeTab) {
          case "nests":
            return (
              <CommunityList
                nests={filteredList}
                handleFunctions={{
                  handleApprove: approvePendingCommunity,
                  handleDelete: deleteCommunity,
                }}
                manageMode={{
                  token,
                }}
              />
            );
          case "users":
            return (
              <>
                {filteredList?.map((user) => {
                  const role = user.role;
                  const isUser = role === "USER";
                  const handleUserClick = (e) => {
                    e.stopPropagation();
                    if (user.status === "active") {
                      navigate(`/user/${user.id}`);
                    }
                  };
                  const updateUserToExpert = async (e) => {
                    e.stopPropagation();
                    const confirmed = window.confirm(
                      "해당 유저를 전문가로 변경하겠습니까?"
                    );
                    if (!confirmed) return;
                    const { code, msg } = await updateUserRole(
                      { target_user_id: user.id, role: "EXPERT" },
                      token
                    );
                    if (code < 300) {
                      alert("전문가로 변경되었습니다");
                      window.location.reload();
                    } else {
                      alert("실패", msg);
                    }
                  };
                  const updateUserToAdmin = async (e) => {
                    e.stopPropagation();
                    const confirmed = window.confirm(
                      "해당 유저를 관리자로 변경하겠습니까?"
                    );
                    if (!confirmed) return;
                    const { code, msg } = await updateUserRole(
                      { target_user_id: user.id, role: "ADMIN" },
                      token
                    );
                    if (code < 300) {
                      alert("관리자로 변경되었습니다");
                      window.location.reload();
                    } else {
                      alert("실패", msg);
                    }
                  };
                  return (
                    <div key={user.id} className={listStyles.innerBox}>
                      <div
                        className={classNames(
                          listStyles.nest,
                          isUser && listStyles.admin
                        )}
                        onClick={handleUserClick}
                      >
                        <div className={listStyles.profile}>
                          <img
                            src={person}
                            className={listStyles.communityIMG}
                            alt="유저 이미지"
                          />
                          <h2>{`${user.username} (${(() => {
                            switch (role) {
                              case "USER":
                                return "유저";
                              case "EXPERT":
                                return "전문가";
                              case "ADMIN":
                                return "관리자";
                              default:
                                return;
                            }
                          })()})`}</h2>
                        </div>
                        <p>{`${user.email} ${(() => {
                          if (user.status === "pending") {
                            return "(인증 미완료)";
                          }
                        })()}`}</p>
                        {isUser && user.status === "active" && (
                          <div
                            className={classNames(
                              listStyles.buttonBox,
                              listStyles.user
                            )}
                          >
                            <button
                              className={classNames(
                                listStyles.button,
                                listStyles.user
                              )}
                              onClick={updateUserToExpert}
                            >
                              <p>전문가로 변경</p>
                            </button>
                            <button
                              className={classNames(
                                listStyles.button,
                                listStyles.user
                              )}
                              onClick={updateUserToAdmin}
                            >
                              <p>관리자로 변경</p>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            );
          default:
            console.log("activeTab 오류: ", activeTab);
            return;
        }
      })()}
    </>
  );

  // return <div className={styles.mainContent}>{content}</div>;
}

export default ManageMode;
